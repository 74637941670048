import React, { useCallback, useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import "rsuite/Accordion/styles/index.css";
import "rsuite/Input/styles/index.css";

import Cookies from "js-cookie";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined"; // (Optional) Import component styles. If you are using Less, import the `index.less` file.
import "rsuite/Input/styles/index.css";
import "rsuite/InputGroup/styles/index.css";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import "../../../../../assets/style/services.css";

import { useDispatch, useSelector } from "react-redux";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import { Country, State, City } from "country-state-city";

import { useTranslation } from "react-i18next";
import {
  city_search_value,
  cleaning_get,
  construction_get,
  construction_search,
  country_search_value,
  electrical_country,
  electrical_get,
  garden_get,
  home_appliance_get,
  home_appliance_search,
  post_get,
  post_search,
  search_city,
  search_country,
  services_get,
  state_search_value,
  vehicle_country,
  vehicle_get,
} from "../../../../../redux/action/services-action";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../../../sub component/show design data/loading";
import Search_loading from "../../../search loading/search-loading";
import HomeFooter from "../../../../../sub component/home/footer";
import PostConstruction_Pagination from "./PostConstruction-Cleaning-page";
import { user_action } from "../../../../../redux/action/cv-action";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@rsuite/icons/Search";
import { styled } from "@mui/material/styles";
import brand_image from "../../../../../assets/image/Brand-photos.png";
import { Helmet } from "react-helmet";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Post_Construction_Cleaning = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [image, setImage] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch all countries
    const countryList = Country.getAllCountries().map((country) => ({
      name: country.name,
      isoCode: country.isoCode,
    }));
    setCountries(countryList);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states of the selected country
      const stateList = State.getStatesOfCountry(selectedCountry.isoCode).map(
        (state) => ({
          name: state.name,
          isoCode: state.isoCode,
        })
      );
      setStates(stateList);
      setSelectedState(null);
      setCities([]);
    } else {
      setStates([]);
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities of the selected state
      const cityList = City.getCitiesOfState(
        selectedCountry.isoCode,
        selectedState.isoCode
      ).map((city) => ({
        name: city.name,
      }));
      setCities(cityList);
    } else {
      setCities([]);
    }
  }, [selectedState, selectedCountry]);

  const handleCountryChange = (e) => {
    const country = countries.find((c) => c.isoCode === e.target.value);
    setSelectedCountry(country || null);
    setCountry(country?.name || "");
    setSelectedState(null);
    setSelectedCity("");
  };

  const handleStateChange = (e) => {
    const state = states.find((s) => s.isoCode === e.target.value);
    setSelectedState(state || null);
    setState(state?.name || "");
    setSelectedCity("");
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);

  const Data = useSelector((state) => state.services.post_get);
  const total_post = useSelector((state) => state.services.total_post);

  const search = useSelector((state) => state.services.search);
  const page = useSelector((state) => state.services.cleaning_page);
  const second_job_page = useSelector((state) => state.job.second_job_page);
  useEffect(() => {
    setData(Data);
  }, [Data]);

  useEffect(() => {
    setData(search);
  }, [search]);
  useEffect(() => {
    setData(page);
  }, [page]);
  useEffect(() => {
    setData(second_job_page);
  }, [second_job_page]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(post_get());
      await dispatch(user_action());
      await dispatch(services_get());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    const Search = async () => {
      setSearchLoading(true);
      await dispatch(
        post_search(country, state, selectedCity)
      );
      setSearchLoading(false);
      dispatch(country_search_value(country));
      dispatch(city_search_value(selectedCity));
      dispatch(state_search_value(state));
    };

    //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
    if (country !== "" || state !== "" || selectedCity !== "") {
      Search();
    }
  }, [country, state, selectedCity, dispatch]);
  const searchPerformed = useSelector(
    (state) => state.services.searchPerformed
  );
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };

  const handleDataFetchComplete = useCallback(() => {
    dispatch(construction_get());
  }, [dispatch]);

  useEffect(() => {
    handleDataFetchComplete();
  }, [handleDataFetchComplete]);
  const token = useSelector((state) => state.cv.isAuthuntucated);
  const navigate = useNavigate();
  const user_id = useSelector((state) => state.cv.user.id);
  const services_data = useSelector((state) => state.services.services_get);
  const [lecturerData, setLecturerData] = useState([]);
  const [actionsCompleted, setActionsCompleted] = useState(false);

  useEffect(() => {
    setLecturerData(services_data);
  }, [services_data]);

  const user = lecturerData.find((user) => user.user_id === `${user_id}`);

  return (

    <>
 <Helmet>
  {/* Basic Meta Tags */}
  <title>Post-Construction Cleaning Services - Job Version</title>
  <meta
    name="description"
    content="Find expert post-construction cleaning services on Job Version. Our professional cleaners ensure your property is spotless and ready for use after construction work."
  />
  <meta
    name="keywords"
    content="Post-Construction Cleaning, Construction Cleanup, Professional Cleaning Services, Job Version, After-Construction Cleaning, Property Cleaning"
  />
  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  {/* Open Graph Tags */}
  <meta
    property="og:title"
    content="Post-Construction Cleaning Services - Job Version"
  />
  <meta
    property="og:description"
    content="Discover top-rated post-construction cleaning services on Job Version. Ensure your property is clean and ready for use with our expert cleaning solutions."
  />
  <meta
    property="og:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta
    property="og:url"
    content="https://jobversion.com/services/Post-Construction-Cleaning"
  />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Job Version" />

  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Post-Construction Cleaning Services - Job Version"
  />
  <meta
    name="twitter:description"
    content="Find reliable post-construction cleaning services on Job Version. Our experts will make sure your property is pristine and ready for use after construction."
  />
  <meta
    name="twitter:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta
    name="twitter:url"
    content="https://jobversion.com/services/Post-Construction-Cleaning"
  />

  {/* Canonical Tag */}
  <link
    rel="canonical"
    href="https://jobversion.com/services/Post-Construction-Cleaning"
  />

  {/* Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "Post-Construction Cleaning Services",
        "description": "Find expert post-construction cleaning services on Job Version. Our professional cleaners ensure your property is spotless and ready for use after construction work.",
        "image": "https://jobversion.com/images/Brand-photos.png",
        "url": "https://jobversion.com/services/Post-Construction-Cleaning",
        "publisher": {
          "@type": "Organization",
          "name": "Job Version",
          "logo": {
            "@type": "ImageObject",
            "url": "https://jobversion.com/images/Brand-photos.png"
          }
        }
      }
    `}
  </script>
</Helmet>

    

<div
      style={{
        direction:
          i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <div>
          {searchLoading ? (
            <Search_loading />
          ) : (
<div className="flex justify-between flex-col min-h-screen">
<div className="flex justify-center min-w-[100%] services-container  pt-[150px]">
                <div className="main-freeLancer-container  services-container max-w-[900px] main-freeLancer-details-container min-w-[90%] flex justify-center gap-[40px]">
                  <div className="left-details-container   ">
                  <div className="left-details  border rounded-[10px]   ">
                  <div className="left-details-header rounded-t-[9px] text-[20px] bg-[#ffb923] h-[45px]  text-white flex  font-[500]   border-top-[20px] justify-center items-center">
                  {t("Filtering_by")}{" "}
                      </div>
                      <div
                        className=" flex text-center flex-end"
                        style={{
                          direction:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "rtl"
                              : "ltr",
                        }}
                      >
                        <div className="flex min-w-full   overflow-auto justify-center ">
                          <div className="grid w-full  mx-[20px]   md:grid-cols-1  mt-[5px]">
                            <Accordion
                              expanded={expanded === "panel6"}
                              onChange={handleChange("panel6")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("Country")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div>
                                  <select
                                    className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={selectedCountry?.isoCode || ""}
                                    onChange={handleCountryChange}
                                  >
                                    <option value="">
                                      {t("select_country")}
                                    </option>
                                    {countries.map((country) => (
                                      <option
                                        key={country.isoCode}
                                        value={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel7"}
                              onChange={handleChange("panel7")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("State")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div>
                                  <select
                                    className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={selectedState?.isoCode || ""}
                                    onChange={handleStateChange}
                                    disabled={!selectedCountry}
                                  >
                                    <option value="">
                                      {t("select_state")}
                                    </option>
                                    {states.map((state) => (
                                      <option
                                        key={state.isoCode}
                                        value={state.isoCode}
                                      >
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel8"}
                              onChange={handleChange("panel8")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("City")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div>
                                  <select
                                    className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={selectedCity}
                                    onChange={(e) =>
                                      setSelectedCity(e.target.value)
                                    }
                                    disabled={!selectedCountry}
                                  >
                                    <option value="">{t("select_city")}</option>
                                    {cities.map((city) => (
                                      <option key={city.name} value={city.name}>
                                        {city.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* phone searchbar */}

                  <div className="main-card-container flex flex-col w-[900px] gap-[50px]">
                  <div className="freeLancers_border p-1 max-w-[900px] min-w-[100%] border rounded-[10px] min-h-[100px]">
                  <div className="flex justify-end">
                  <Link
                            to={token ?user ? "/services/update " : "/services/add" :'/login'}
                            className="left-search-button flex justify-center md:text-[15px]  text-[12px] items-center md:mt-[-50px]   mt-[-40px] md:w-[160px] w-[120px] max-w-[135px] md:max-w-[160px] bg-[#ffb923] rounded-[5px] text-white md:h-[40px] h-[30px] hover:bg-[#ffcc5f]"
                          >
                            {
                                 user ?
                            t("update_service")
:
t("Add_service")


                            }
                          </Link>
                      </div>
                      {data.length === 0 && (
                        <div className="search-message flex justify-center text-center items-center h-[100%] main-freeLancer-container-text">
                          {t("not_haveData")}
                        </div>
                      )}

                      <div className="freelancer-card-container p-1">
                        {data.map((item, index) => (
                          <Link
                            to={
                              token
                                ? `/services/services-detail/${item.id}`
                                : "/login"
                            }
                            key={index}
                          >
                            <Card
                              sx={{ minWidth: "100%", boxShadow: "none" }}
                              className="p-1"
                            >
                              <CardHeader
                                avatar={
                                  <img
                                    src={item.image || brand_image}
                                    className="rounded-full card-avatar p-0.5   border-red-900"
                                    alt="Avatar"
                                    style={{
                                      marginLeft:
                                        i18n.language === "ku" ||
                                        i18n.language === "ar"
                                          ? "20px"
                                          : "0px",
                                    }}
                                  />
                                }
                                title={
                                  <h1
                                    variant="body1"
                                    className="freelancer-title"
                                  >
                                    {item.title}
                                  </h1>
                                }
                                subheader={
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontFamily: '"Poppins", sans-serif',
                                      fontStyle: "normal",
                                    }}
                                    className="main-freeLancer-container-text break-words"
                                  >
                                    <span>{item.type}</span>
                                    <span className="ml-[15px]">
                                      <LocationOnIcon
                                        style={{
                                          color: "gray",
                                          fontSize: "18px",
                                        }}
                                      />
                                      <span className="ml-[5px]">
                                        {item.city} , {item.state} ,{" "}
                                        {item.country}
                                      </span>
                                    </span>
                                  </Typography>
                                }
                              />
                              <CardContent>
                                <p className="main-freeLancer-container-text break-words">
                                  {item.description}
                                </p>
                              </CardContent>
                              <CardActions disableSpacing className="mb-[-18px]">
                                <IconButton
                                  aria-label="add to favorites"
                                  onClick={() => handlePhoneCall(item.phone)}
                                >
                                  <LocalPhoneIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="email"
                                  onClick={() => handleSendEmail(item.email)}
                                >
                                  <EmailIcon />
                                </IconButton>
                              </CardActions>
                              {index !== data.length - 1 && <hr className=" mx-[20px] mt-[10px]" />}
                            </Card>
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className="freelancer-pagination mb-[80px] flex justify-center">
                      {total_post >10 ?
                        <div>
                          <PostConstruction_Pagination />{" "}
                        </div>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="max-w-full">
                <HomeFooter />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
    </>

  );
};

export default Post_Construction_Cleaning;
