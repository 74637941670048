import React, { useEffect, useState } from "react";
import Headers from "../sub component/home/headers";
import About_cv from "../sub component/home/about-cv";
import GetStarted from "../sub component/home/get-starded";
import { Helmet } from "react-helmet";
import Home_footer from "../sub component/home/footer";
import Cv_designs from "../sub component/home/cv-designs";
import How_itWorks from "../sub component/home/how-itWorks";
import FreeLancer from "../sub component/home/freeLancer";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../sub component/show design data/loading";
import { freelancer_get } from "../redux/action/freelancer-action";
import Maintenance_and_Services from "../sub component/home/maintenance-services";
import Jobs from "../sub component/home/jobs";
import How_doesIt_work from "../sub component/home/how-doesIt-work";
import Lecturers from "../sub component/home/Education-traning";
import Doctors from "../sub component/home/doctors";
import Pricing from "../sub component/home/pricing";
import { job_details, job_get } from "../redux/action/job-action";
import { user_action } from "../redux/action/cv-action";
import Cookies from "js-cookie";

const Home = () => {
  useEffect(() => {
    // Get query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const accessToken = queryParams.get("user");

    // If accessToken is present, set the cookie
    if (accessToken) {
      Cookies.set("jwt", accessToken, { expires: 7 });
    }

    // Update the load state (if needed)
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const freeelance_loading = useSelector((state) => state.freelancer.loading);
  const job_loading = useSelector((state) => state.job.loading);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(job_get());
      await dispatch(freelancer_get());
      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const freeLanceData = useSelector((state) => state.freelancer.freelancer_get);

  useEffect(() => {}, [freeLanceData]);
  return (
    <div className="">
      <Helmet>
        {/* Basic Meta Tags */}
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
    <link
      rel="apple-touch-icon"
      href="%PUBLIC_URL%/favicon.ico"
      sizes="192x192"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    
        <title>
          Job Version - Advanced Tools for Career Development and Job
          Opportunities
        </title>
        <meta
          name="description"
          content="Job Version is your ultimate career development platform. Discover powerful tools for CV building, job searching, freelance opportunities, and professional services. Explore a range of job listings, connect with top professionals, and find educators to support your growth."
        />
        <meta
          name="keywords"
          content="CV builder, job search, freelancers, career services, professional growth, job listings, career advancement, educational support, Job Version, job opportunities, career tools"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Job Version - Comprehensive Career Solutions and Professional Development"
        />
        <meta
          property="og:description"
          content="Job Version provides comprehensive solutions for career development. From CV creation to job search, freelance opportunities to educational support, explore top professionals and services that drive your career forward."
        />
        <meta
          property="og:image"
          content="https://jobversion.com/images/Brand-photos.png"
        />
        <meta property="og:url" content="https://jobversion.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Job Version" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Job Version - Premier Platform for Career Growth and Job Opportunities"
        />
        <meta
          name="twitter:description"
          content="Elevate your career with Job Version. Our platform offers CV building, job searches, freelance connections, and more. Discover top professionals and services that align with your career goals."
        />
        <meta
          name="twitter:image"
          content="https://jobversion.com/images/Brand-photos.png"
        />
        <meta name="twitter:url" content="https://jobversion.com/" />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://jobversion.com/" />

        {/* Sitemap Location */}
        <link
          rel="sitemap"
          type="application/xml"
          href="https://jobversion.com/sitemap.xml"
        />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "Job Version",
        "url": "https://jobversion.com/",
        "description": "Job Version is a leading platform for career advancement, offering tools for CV creation, job searching, and connecting with professionals and services.",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://jobversion.com/?s={search_term_string}",
          "query-input": "required name=search_term_string"
        },
        "logo": {
          "@type": "ImageObject",
          "url": "https://jobversion.com/images/Brand-photos.png",
          "width": 800,
          "height": 600
        }
      }
    `}
        </script>

        {/* Additional Structured Data for Local Business */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Job Version",
        "url": "https://jobversion.com/",
        "logo": "https://jobversion.com/images/Brand-photos.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "Customer Service",
          "telephone": "+964 7517252321",
          "email": "support@jobversion.com"
        },
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Kirkuk",
          "addressLocality": "Kirkuk",
          "addressRegion": "Kirkuk Governorate",
          "postalCode": "36001",
          "addressCountry": "Iraq"
        }
      }
    `}
        </script>
      </Helmet>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Headers />
          <About_cv />
          {/* <Pricing /> */}

          {/* <How_doesIt_work /> */}
          {/* <How_itWorks /> */}
          <Cv_designs />
          <Jobs />
          <FreeLancer />
          <Lecturers />
          {/* <Doctors /> */}
          <Maintenance_and_Services />
          {/* <GetStarted /> */}
          <Home_footer />
        </>
      )}
    </div>
  );
};

export default Home;
