import React, { useEffect, useState } from "react";
import HomeFooter from "../sub component/home/footer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { user_action } from "../redux/action/cv-action";
import Loading from "../sub component/show design data/loading";
import { Helmet } from "react-helmet";
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from "react-router-dom";
const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());

      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const { t, i18n } = useTranslation();  return (
  <>
<Helmet>
  {/* Basic Meta Tags */}
  <title>Contact Us - Job Version</title>
  <meta
    name="description"
    content="Get in touch with the Job Version team for any inquiries, support, or feedback. Find all the contact information you need to reach out to us and get the help you need."
  />
  <meta
    name="keywords"
    content="Contact Job Version, customer support, contact information, reach out, inquiries, feedback"
  />
  <meta name="robots" content="index, follow" />

  {/* Open Graph Tags */}
  <meta property="og:title" content="Contact Us - Job Version" />
  <meta
    property="og:description"
    content="Need assistance or have a question? Contact the Job Version team for support, inquiries, or feedback. We are here to help you with any concerns or questions you may have."
  />
  <meta
    property="og:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta property="og:url" content="https://jobversion.com/contact-us" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Job Version" />

  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Contact Us - Job Version" />
  <meta
    name="twitter:description"
    content="Reach out to Job Version for any inquiries, support, or feedback. Our contact page provides all the details you need to get in touch with our team."
  />
  <meta
    name="twitter:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta name="twitter:url" content="https://jobversion.com/contact-us" />

  {/* Canonical Tag */}
  <link rel="canonical" href="https://jobversion.com/contact-us" />

  {/* Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "name": "Contact Us - Job Version",
        "url": "https://jobversion.com/contact-us",
        "description": "Get in touch with Job Version for support, inquiries, or feedback. Find all the contact information you need to reach out to us.",
        "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "Customer Support",
          "telephone": "+964 7517252321",
          "email": "support@jobversion.com"
        },
        "organization": {
          "@type": "Organization",
          "name": "Job Version",
          "logo": "https://jobversion.com/images/Brand-photos.png"
        }
      }
    `}
  </script>
</Helmet>


  {
    loading?
<Loading/>
:
<div className="min-h-screen flex justify-between flex-col">
<section  style={{
        direction:
          i18n.language === "ku" || i18n.language === "ar"
            ? "rtl"
            : "ltr",
      }} class="">
  <div class="max-w-7xl  mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
    <div class="max-w-2xl lg:max-w-4xl mx-auto text-center">
      {/* <h2 class="text-3xl font-extrabold text-gray-900">
{t("Contact_us")}            </h2> */}
     
    </div>
    <div class="mt-16 lg:mt-20">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div class="rounded-lg overflow-hidden">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d207984.98062308642!2d44.53155572901793!3d35.460603910818016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1554d6034577edc9%3A0x5890c4555c9da475!2z2YPYsdmD2YjZg9iMINmD2LHZg9mI2YMg2YXYrdin2YHYuNip!5e0!3m2!1sar!2siq!4v1724263694601!5m2!1sar!2siq"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div>
          <div class="max-w-full mx-auto rounded-lg overflow-hidden">
            <div class="px-6 py-4">
              <h3 class="text-lg font-medium text-gray-900">
              {t("address")}   
              </h3>
              <p class="mt-1 text-gray-600">
                Kirkuk , kirkuk governorate , Iraq                   </p>
            </div>
         
            <div class="border-t border-gray-200 px-6 py-4">
              <h3 class="text-lg font-medium text-gray-900">{t("Contact_us")}</h3>
              <p class="mt-1 text-gray-600">Email: info@jobversion.com                   </p>
              <p class="mt-1 text-gray-600">Phone: +964 751 725 23 21 , +964 772 725 23 23

                <p> 9am - 5pm</p>
              </p>
              <div className="flex gap-3 mt-[30px]">
            <a href="https://www.instagram.com/jobversion?igsh=cG9qbzJ5N2FkeTJo&utm_source=qr" className="hover:text-[#ffb923] text-gray-800 ">
            <InstagramIcon style={{fontSize:"35px"}}/>
            </a>        
            <a href="https://t.me/muhammadhammzaa" className="hover:text-[#ffb923] text-gray-800">
            <TelegramIcon style={{fontSize:"35px"}}/>
            </a>
            <a href="https://api.whatsapp.com/send/?phone=9647517252321&text&type=phone_number&app_absent=0" className="hover:text-[#ffb923] text-gray-800">
            <WhatsAppIcon style={{fontSize:"35px"}} />
            </a>
            </div>
            </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<HomeFooter />
</div>
  }
  
  </>
  );
};

export default ContactUs;
