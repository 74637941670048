import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import "../assets/style/navbar.css";

import brand from "../assets/image/Brand.png";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { Dropdown } from "flowbite-react";
import Lang_swith from "../sub component/appbar/lang_swith";
import longCV from "../locale/cv articles/articles.json";
import freelancer_add from "../locale/freelancer_add.json";
import error404 from "../locale/error404.json";
import home from "../locale/home.json";
import jobs from "../locale/jobs.json";
import services from "../locale/services.json";
import lecturers from "../locale/lecturers.json";
import auth from "../locale/auth.json";
import Cookies from "js-cookie";
import cv from "../locale/cv.json";

import { useDispatch, useSelector } from "react-redux";
import { updateProperty } from "../redux/action/property-action";
import { useEffect } from "react";
import { user_action } from "../redux/action/cv-action";
import Sections from "../sub component/appbar/Sections";

i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "Welcome to React": "Welcome to React and react-i18next",
          jobs: "Jobs",
          "About us": "About us",
          "Contact us": "Contact us",
          "create cv": "Create CV",
          language: "Language",

          //long cv
          "long cv title": longCV.en.title,
          "main header long cv": longCV.en.main,
          "long text1": longCV.en.text1,
          "long text2": longCV.en.text2,
          "long text3": longCV.en.text3,
          "long text4": longCV.en.text4,
          "long text5": longCV.en.text5,
//navbar
Sections: home.en.Sections,


          //cv
          Please_log_in: cv.en.Please_log_in,
          sub_title: cv.en.sub_title,

          summary: cv.en.summary,
          Contact: cv.en.Contact,
          Education: cv.en.Education,
          Add_Education: cv.en.Add_Education,
          School: cv.en.School,
          City: cv.en.City,
          Start_date: cv.en.Start_date,
          End_date: cv.en.End_date,

          level: cv.en.level,
          Title: cv.en.Title,
          Course: cv.en.Course,
          Add_Course: cv.en.Add_Course,
          Work_experience: cv.en.Work_experience,
          add_Work_experience: cv.en.add_Work_experience,
          Add_skills: cv.en.Add_skills,
          Add_Language: cv.en.Add_Language,
          image_select: cv.en.image_select,
          select_file: cv.en.select_file,

          //freelancer
          Top_Freelancers_search: freelancer_add.en.Top_Freelancers_search,

          Reviews: freelancer_add.en.Reviews,
          My_Review: freelancer_add.en.My_Review,
          comment: freelancer_add.en.comment,

          update_freelancer: freelancer_add.en.update_freelancer,
          freelancer_update: freelancer_add.en.freelancer_update,
          freelancer_profile: freelancer_add.en.freelancer_profile,
          freelance_details: freelancer_add.en.freelance_details,

          one_to_3_years: freelancer_add.en.one_to_3_years,
          four_to_6_years: freelancer_add.en.four_to_6_years,
          more_than_6: freelancer_add.en.more_than_6,

          freelancer_Name: freelancer_add.en.freelancer_Name,
          phone_number: freelancer_add.en.phone_number,
          Email: freelancer_add.en.Email,
          Skills: freelancer_add.en.Skills,
          Country: freelancer_add.en.Country,
          City: freelancer_add.en.City,
          Functional_Area: freelancer_add.en.Functional_Area,
          currency: freelancer_add.en.currency,
          Description: freelancer_add.en.Description,
          send: freelancer_add.en.send,
          Make_sure_you_fill_out_all_the_information_correctly:
            freelancer_add.en
              .Make_sure_you_fill_out_all_the_information_correctly,
          Add_freelance: freelancer_add.en.Add_freelance,
          Created_successfully: freelancer_add.en.Created_successfully,
          Finding_a_job: freelancer_add.en.Finding_a_job,
          freelancers_list: freelancer_add.en.freelancers_list,
          per_hour_price: freelancer_add.en.per_hour_price,
          experience: freelancer_add.en.experience,
          Updated_successfully: freelancer_add.en.Updated_successfully,
          //main
          freelancer: freelancer_add.en.freelancer,
          search: freelancer_add.en.search,
          add_freelancer: freelancer_add.en.add_freelancer,
          freelancer_not_found: freelancer_add.en.freelancer_not_found,
          //details
          address: freelancer_add.en.address,
          years: freelancer_add.en.years,
          //home how it works
          how_works_create_acount: home.en.how_works_create_acount,
          how_works_create_acount_content:
            home.en.how_works_create_acount_content,
          how_works_select_template: home.en.how_works_select_template,
          how_works_select_template_content:
            home.en.how_works_select_template_content,
          how_works_create_cv: home.en.how_works_create_cv,
          how_works_create_cv_content: home.en.how_works_create_cv_content,
          how_works_find_job: home.en.how_works_find_job,
          how_works_find_job_content: home.en.how_works_find_job_content,


          //cv info
          What_isCV_content: home.en.What_isCV_content,
          How_writeCV_content: home.en.How_writeCV_content,
          purpose_cv_content: home.en.purpose_cv_content,
          type_cv_content: home.en.type_cv_content,

          Create_CV: cv.en.Create_CV,

          //footer
          Resources: home.en.Resources,
          Contact_us: home.en.Contact_us,
          About_us: home.en.About_us,
          social_media: home.en.social_media,
          language: home.en.language,
          All_rights_reserved: home.en.All_rights_reserved,
          get_started: home.en.get_started,
          Support: home.en.Support,
          Developed_by: home.en.Developed_by,

          //headder
          header_main_text: home.en.header_main_text,
          header_sub_main_text: home.en.header_sub_main_text,
          Create_resume: home.en.Create_resume,
          Find_Job: home.en.Find_Job,
          welcome_to: home.en.welcome_to,
          header_main_text1: home.en.header_main_text1,
          header_main_text2: home.en.header_main_text2,
          Home: home.en.Home,

          //about
          about_cv_top_text: home.en.about_cv_top_text,
          about_cv_bottom_text: home.en.about_cv_bottom_text,
          about_cv_main_text: home.en.about_cv_main_text,

          //cv articles

          whatis_content: longCV.en.whatis_content,
          whatis_header: longCV.en.whatis_header,
          cv_howTo_write_content: longCV.en.cv_howTo_write_content,
          cv_howTo_write_header: longCV.en.cv_howTo_write_header,
          cv_purpose_content: longCV.en.cv_purpose_content,
          cv_purpose_header: longCV.en.cv_purpose_header,
          types_there: longCV.en.types_there,

          type_main: longCV.en.type_main,
          Chronological_CV: longCV.en.Chronological_CV,

          other_types: longCV.en.other_types,
          Europass_cv: longCV.en.Europass_cv,
          academic_cv: longCV.en.academic_cv,
          skill_based: longCV.en.skill_based,
          creative_cv: longCV.en.creative_cv,

          skill_based_header: longCV.en.skill_based_header,
          other_header: longCV.en.other_header,
          europas_header: longCV.en.europas_header,
          Academic_header: longCV.en.Academic_header,
          creative_header: longCV.en.creative_header,

          //cv artcles

          What_isCV: home.en.What_isCV,
          How_writeCV: home.en.How_writeCV,
          purpose_cv: home.en.purpose_cv,
          header_main_text2: home.en.header_main_text2,
          type_cv: home.en.type_cv,

          //about us
          Our_Mission: home.en.Our_Mission,
          Our_Mission_content: home.en.Our_Mission_content,
          Our_Values: home.en.Our_Values,
          Innovation: home.en.Innovation,
          Innovation_content: home.en.Innovation_content,
          Integrity: home.en.Integrity,
          Integrity_content: home.en.Integrity_content,
          Customer_Centricity: home.en.Customer_Centricity,
          Customer_Centricity_content: home.en.Customer_Centricity_content,
          contact_content: home.en.contact_content,

          manager: home.en.manager,
          manager_description: home.en.manager_description,
          manager_role: home.en.manager_role,


          ceate_cv_content: home.en.ceate_cv_content,
          jobs_content: home.en.jobs_content,
          freelance_content: home.en.freelance_content,
          services_content: home.en.services_content,
          lecturers_content: home.en.lecturers_content,

          What_We_Offer: home.en.What_We_Offer,

          //pricing

          //info
          free_info: home.en.free_info,
          monthly_info: home.en.monthly_info,
          yearly_info: home.en.yearly_info,

          freePlan_is_used: home.en.freePlan_is_used,
          freePlan_succesfuly_Actived: home.en.freePlan_succesfuly_Actived,
          actived_plan: home.en.actived_plan,
          fib_info: home.en.fib_info,

          payment_important: home.en.payment_important,
          payment_monyBack: home.en.payment_monyBack,

          business_account: home.en.business_account,
          Personal_account: home.en.Personal_account,

          Payment_Successful: home.en.Payment_Successful,
          Payment_failed: home.en.Payment_failed,

          Buy_Now: home.en.Buy_Now,
          buy_help: home.en.buy_help,

          Log_out: home.en.Log_out,

          pricing: home.en.pricing,
          pricing_plan: home.en.pricing_plan,
          Choose_your_plan: home.en.Choose_your_plan,
          Pricing_content: home.en.Pricing_content,

          Free: home.en.Free,
          Free_content: home.en.Free_content,
          Monthly: home.en.Monthly,
          Monthly_content: home.en.Monthly_content,
          Yearly: home.en.Yearly,
          Yearly_content: home.en.Yearly_content,
          paid: home.en.paid,
          Starter: home.en.Starter,
          Most_popular: home.en.Most_popular,

          //jobs
          Apply_now: jobs.en.Apply_now,

          job_byTitle: jobs.en.job_byTitle,
          job_byFunctional_area: jobs.en.job_byFunctional_area,
          Jobs_By_Industry: jobs.en.Jobs_By_Industry,
          Jobs_By_Skill: jobs.en.Jobs_By_Skill,
          Jobs_By_Country: jobs.en.Jobs_By_Country,
          Jobs_By_CompanyName: jobs.en.Jobs_By_CompanyName,
          Jobs_By_City: jobs.en.Jobs_By_City,
          expired: jobs.en.expired,

          company_name: jobs.en.company_name,
          gender: jobs.en.gender,
          female: jobs.en.female,
          male: jobs.en.male,

          Period: jobs.en.Period,
          add_job: jobs.en.add_job,




          years_ago: jobs.en.years_ago,
          months_ago: jobs.en.months_ago,
          days_ago: jobs.en.days_ago,
          hours_ago: jobs.en.hours_ago,
          seconds_ago: jobs.en.seconds_ago,
          minutes_ago: jobs.en.minutes_ago,
         

//single date
year_ago: jobs.en.year_ago,
month_ago: jobs.en.month_ago,
day_ago: jobs.en.day_ago,
hour_ago: jobs.en.hour_ago,
second_ago: jobs.en.second_ago,
minute_ago: jobs.en.minute_ago,

          //job details
          Job_Title: jobs.en.Job_Title,
          Job_Type: jobs.en.Job_Type,
          Experience: jobs.en.Experience,
          Salary: jobs.en.Salary,
          Address: jobs.en.Address,
          Expire_Date: jobs.en.Expire_Date,
          Industry: jobs.en.Industry,
          Contact_Information: jobs.en.Contact_Information,
          job_details: jobs.en.job_details,

          Jobs_By_type: jobs.en.Jobs_By_type,
          Jobs_By_state: jobs.en.Jobs_By_state,
          Jobs_By_Experience: jobs.en.Jobs_By_Experience,
          Jobs_By_degree: jobs.en.Jobs_By_degree,
          update: jobs.en.update,
          delete: jobs.en.delete,
          //services
          order_now: services.en.order_now,
          Cancel: services.en.Cancel,

          
          update_service: services.en.update_service,

          Cleaning_the_house: services.en.Cleaning_the_house,
          Vehicle_Maintenance: services.en.Vehicle_Maintenance,
          Electrical_Repairs: services.en.Electrical_Repairs,
          Freight_Forwarding: services.en.Freight_Forwarding,
          Home_ApplianceRepairs: services.en.Home_ApplianceRepairs,
          Maintenance: services.en.Maintenance,
          CoolingHeating: services.en.CoolingHeating,
          Car_Wash: services.en.Car_Wash,
          Electronic_Services: services.en.Electronic_Services,
          Duct_Cleaning: services.en.Duct_Cleaning,
          Skincare_Treatments: services.en.Skincare_Treatments,
          Makeup: services.en.Makeup,
          Nail: services.en.Nail,
          Veterinary: services.en.Veterinary,
          Hairdressing: services.en.Hairdressing,
          Mortgage: services.en.Mortgage,
          Legal_Consultation: services.en.Legal_Consultation,
          Photography: services.en.Photography,
          Tire_Replacement: services.en.Tire_Replacement,
          Battery_Services: services.en.Battery_Services,
          construction_of_houses: services.en.construction_of_houses,
          Carpet: services.en.Carpet,
          Post: services.en.Post,
          Travel_Tourism: services.en.Travel_Tourism,
          Garden_Maintenance: services.en.Garden_Maintenance,

          //add services
          Add_service: services.en.Add_service,
          services_list: services.en.services_list,
          my_services: services.en.my_services,
          Category: services.en.Category,
          delevery: services.en.delevery,
          price: services.en.price,
          error: services.en.error,
          image: services.en.image,
          choose_a_photo: services.en.choose_a_photo,
          State: services.en.State,
          select_country: services.en.select_country,
          select_state: services.en.select_state,
          select_city: services.en.select_city,
          select_currency: services.en.select_currency,
          Title: services.en.Title,
          not_haveData: services.en.not_haveData,
          not_haveServices: services.en.not_haveServices,
          available: services.en.available,
          not_available: services.en.not_available,

          service_title: services.en.service_title,
          service_type: services.en.service_type,
          service_detail: services.en.service_detail,

          //lecturers
          name: lecturers.en.name,
          language: lecturers.en.language,
          duration: lecturers.en.duration,
          study_mode: lecturers.en.study_mode,
          numberOf_years_teaching: lecturers.en.numberOf_years_teaching,
          grade_level: lecturers.en.grade_level,
          Subject: lecturers.en.Subject,
          Certifications: lecturers.en.Certifications,

          primary_header: lecturers.en.primary_header,
          primary_content: lecturers.en.primary_content,

          middle_header: lecturers.en.middle_header,
          middle_content: lecturers.en.middle_content,

          High_header: lecturers.en.High_header,
          High_content: lecturers.en.High_content,
          Update_your_profile: lecturers.en.Update_your_profile,

          
          //home headers
          CV_templates: home.en.CV_templates,
          Jobs: home.en.Jobs,
          Freelancers: home.en.Freelancers,
          lecturers: home.en.lecturers,
          Services: home.en.Services,

          //filter by
          Filtering_by: jobs.en.Filtering_by,

          Show_all: home.en.Show_all,

          Kurdish: lecturers.en.Kurdish,
          Arabic: lecturers.en.Arabic,
          English: lecturers.en.English,
          math: lecturers.en.math,
          science: lecturers.en.science,
          chemistry: lecturers.en.chemistry,
          Physics: lecturers.en.Physics,
          Biology: lecturers.en.Biology,
          computer: lecturers.en.computer,
          economy: lecturers.en.economy,
          Primary_School: lecturers.en.Primary_School,
          Middle_School: lecturers.en.Middle_School,
          High_School: lecturers.en.High_School,
          add_lecturer: lecturers.en.add_lecturer,
          lecturer_detail: lecturers.en.lecturer_detail,
          Primary_School_lecturer_list:
            lecturers.en.Primary_School_lecturer_list,
          Middle_School_lecturer_list: lecturers.en.Middle_School_lecturer_list,
          High_School_lecturer_list: lecturers.en.High_School_lecturer_list,

          my_lecturer_profile: lecturers.en.my_lecturer_profile,
          update_Lecturer: lecturers.en.update_Lecturer,
          bachelors: lecturers.en.bachelors,
          master: lecturers.en.master,
          Doctorate: lecturers.en.Doctorate,

          //auth
          Verify_Email: auth.en.Verify_Email,
          Email_Verification: auth.en.Email_Verification,
          Verify_your_Email: auth.en.Verify_your_Email,

          Sign_account: auth.en.Sign_account,
          Email_not_found: auth.en.Email_not_found,
          Email_required: auth.en.Email_required,
          Incorrect_password: auth.en.Incorrect_password,
          Password_required: auth.en.Password_required,
          Forgot_password: auth.en.Forgot_password,
          Sign_in: auth.en.Sign_in,
          Or: auth.en.Or,
          Login_with_Google: auth.en.Login_with_Google,
          Login_with_Facebook: auth.en.Login_with_Facebook,
          Dont_have_account: auth.en.Dont_have_account,
          email: auth.en.email,
          Password: auth.en.Password,
          Sign_up: auth.en.Sign_up,

          name_required: auth.en.name_required,
          Sign_up_register: auth.en.Sign_up_register,
          email_already_taken: auth.en.email_already_taken,
          password_not_match: auth.en.password_not_match,
          password_atLeast: auth.en.password_atLeast,

          Sign_up_button: auth.en.Sign_up_button,
          have_acount: auth.en.have_acount,
          Confirm_Password: auth.en.Confirm_Password,

          Email_Verification: auth.en.Email_Verification,
          verify_header: auth.en.verify_header,
          Invalid_verification: auth.en.Invalid_verification,

          Verify_account: auth.en.Verify_account,
          dont_receive_code: auth.en.dont_receive_code,
          verified_successfully: auth.en.verified_successfully,
          Resended_code: auth.en.Resended_code,
          Resend: auth.en.Resend,

          Password_reset_successful: auth.en.Password_reset_successful,
          Reset_Password: auth.en.Reset_Password,
          send_reset_link: auth.en.send_reset_link,
          password_confirm: auth.en.password_confirm,
          login_successful: auth.en.login_successful,

          //error page
          errorPage: error404.en.errorPage,
          Page_not_found: error404.en.Page_not_found,
          buttonHome: error404.en.buttonHome,
          buttonContact_support: error404.en.buttonContact_support,
        },
      },
      ar: {
        translation: {
          jobs: "وظائف",
          home: "الصفحة الرئيسية",
          "About us": "من نحن",
          "Contact us": "اتصل بنا",
          "create cv": "انشاء  السيرة الذاتية ",
          language: "اللغة",

          //long cv
          "long cv title": longCV.ar.title,
          "main header long cv": longCV.ar.main,
          "long text1": longCV.ar.text1,
          "long text2": longCV.ar.text2,
          "long text3": longCV.ar.text3,
          "long text4": longCV.ar.text4,
          "long text5": longCV.ar.text5,
          //navbar
Sections: home.ar.Sections,


          //cv
          Please_log_in: cv.ar.Please_log_in,
          Create_CV: cv.ar.Create_CV,

          sub_title: cv.ar.sub_title,
          summary: cv.ar.summary,
          Contact: cv.ar.Contact,
          Education: cv.ar.Education,
          Add_Education: cv.ar.Add_Education,
          School: cv.ar.School,
          City: cv.ar.City,
          Start_date: cv.ar.Start_date,
          End_date: cv.ar.End_date,
          image_select: cv.ar.image_select,
          select_file: cv.ar.select_file,
          level: cv.ar.level,
          Title: cv.ar.Title,
          Course: cv.ar.Course,
          Add_Course: cv.ar.Add_Course,
          Work_experience: cv.ar.Work_experience,
          add_Work_experience: cv.ar.add_Work_experience,
          Add_skills: cv.ar.Add_skills,
          Add_Language: cv.ar.Add_Language,

          //freelancer add
          Top_Freelancers_search: freelancer_add.ar.Top_Freelancers_search,
          Reviews: freelancer_add.ar.Reviews,
          My_Review: freelancer_add.ar.My_Review,
          comment: freelancer_add.ar.comment,

          freelance_details: freelancer_add.ar.freelance_details,

          one_to_3_years: freelancer_add.ar.one_to_3_years,
          four_to_6_years: freelancer_add.ar.four_to_6_years,
          more_than_6: freelancer_add.ar.more_than_6,

          update_freelancer: freelancer_add.ar.update_freelancer,
          freelancer_update: freelancer_add.ar.freelancer_update,
          freelancer_profile: freelancer_add.ar.freelancer_profile,
          freelancer_Name: freelancer_add.ar.freelancer_Name,
          phone_number: freelancer_add.ar.phone_number,
          Email: freelancer_add.ar.Email,
          Skills: freelancer_add.ar.Skills,
          Country: freelancer_add.ar.Country,
          City: freelancer_add.ar.City,
          Functional_Area: freelancer_add.ar.Functional_Area,
          currency: freelancer_add.ar.currency,
          Description: freelancer_add.ar.Description,
          send: freelancer_add.ar.send,
          Make_sure_you_fill_out_all_the_information_correctly:
            freelancer_add.ar
              .Make_sure_you_fill_out_all_the_information_correctly,
          Add_freelance: freelancer_add.ar.Add_freelance,
          Created_successfully: freelancer_add.ar.Created_successfully,
          Finding_a_job: freelancer_add.ar.Finding_a_job,
          freelancers_list: freelancer_add.ar.freelancers_list,
          per_hour_price: freelancer_add.ar.per_hour_price,
          experience: freelancer_add.ar.experience,
          Updated_successfully: freelancer_add.ar.Updated_successfully,
          //main
          freelancer: freelancer_add.ar.freelancer,
          search: freelancer_add.ar.search,
          add_freelancer: freelancer_add.ar.add_freelancer,
          freelancer_not_found: freelancer_add.ar.freelancer_not_found,
          address: freelancer_add.ar.address,
          years: freelancer_add.ar.years,
          //footer
          Resources: home.ar.Resources,
          Contact_us: home.ar.Contact_us,
          About_us: home.ar.About_us,
          social_media: home.ar.social_media,
          language: home.ar.language,
          All_rights_reserved: home.ar.All_rights_reserved,
          get_started: home.ar.get_started,
          Support: home.ar.Support,
          Developed_by: home.ar.Developed_by,

          //cv info
          What_isCV_content: home.ar.What_isCV_content,
          How_writeCV_content: home.ar.How_writeCV_content,
          purpose_cv_content: home.ar.purpose_cv_content,
          type_cv_content: home.ar.type_cv_content,

          //home how it works
          how_works_create_acount: home.ar.how_works_create_acount,
          how_works_create_acount_content:
            home.ar.how_works_create_acount_content,
          how_works_select_template: home.ar.how_works_select_template,
          how_works_select_template_content:
            home.ar.how_works_select_template_content,
          how_works_create_cv: home.ar.how_works_create_cv,
          how_works_create_cv_content: home.ar.how_works_create_cv_content,
          how_works_find_job: home.ar.how_works_find_job,
          how_works_find_job_content: home.ar.how_works_find_job_content,
          Step1: home.ar.Step1,
          Step2: home.ar.Step2,
          Step3: home.ar.Step3,
          Step4: home.ar.Step4,
          Home: home.ar.Home,

          //cv articles
          whatis_content: longCV.ar.whatis_content,
          whatis_header: longCV.ar.whatis_header,
          cv_howTo_write_content: longCV.ar.cv_howTo_write_content,
          cv_howTo_write_header: longCV.ar.cv_howTo_write_header,
          cv_purpose_content: longCV.ar.cv_purpose_content,
          cv_purpose_header: longCV.ar.cv_purpose_header,
          types_there: longCV.ar.types_there,

          type_main: longCV.ar.type_main,
          Chronological_CV: longCV.ar.Chronological_CV,

          other_types: longCV.ar.other_types,
          Europass_cv: longCV.ar.Europass_cv,
          academic_cv: longCV.ar.academic_cv,
          skill_based: longCV.ar.skill_based,
          creative_cv: longCV.ar.creative_cv,

          skill_based_header: longCV.ar.skill_based_header,
          other_header: longCV.ar.other_header,
          europas_header: longCV.ar.europas_header,
          Academic_header: longCV.ar.Academic_header,
          creative_header: longCV.ar.creative_header,

          Show_all: home.ar.Show_all,

          //headder
          header_main_text: home.ar.header_main_text,
          header_sub_main_text: home.ar.header_sub_main_text,
          Create_resume: home.ar.Create_resume,
          Find_Job: home.ar.Find_Job,
          welcome_to: home.ar.welcome_to,
          header_main_text1: home.ar.header_main_text,
          header_main_text2: home.ar.header_main_text2,

          //about
          about_cv_top_text: home.ar.about_cv_top_text,
          about_cv_bottom_text: home.ar.about_cv_bottom_text,
          about_cv_main_text: home.ar.about_cv_main_text,

          What_isCV: home.ar.What_isCV,
          How_writeCV: home.ar.How_writeCV,
          purpose_cv: home.ar.purpose_cv,
          header_main_text2: home.ar.header_main_text2,
          type_cv: home.ar.type_cv,
          //about us
          Our_Mission: home.ar.Our_Mission,
          Our_Mission_content: home.ar.Our_Mission_content,
          Our_Values: home.ar.Our_Values,
          Innovation: home.ar.Innovation,
          Innovation_content: home.ar.Innovation_content,
          Integrity: home.ar.Integrity,
          Integrity_content: home.ar.Integrity_content,
          Customer_Centricity: home.ar.Customer_Centricity,
          Customer_Centricity_content: home.ar.Customer_Centricity_content,
          contact_content: home.ar.contact_content,

          manager: home.ar.manager,
          manager_description: home.ar.manager_description,
          manager_role: home.ar.manager_role,

          ceate_cv_content: home.ar.ceate_cv_content,
          jobs_content: home.ar.jobs_content,
          freelance_content: home.ar.freelance_content,
          services_content: home.ar.services_content,
          lecturers_content: home.ar.lecturers_content,

          What_We_Offer: home.ar.What_We_Offer,

          //pricing
          //info
          free_info: home.ar.free_info,
          monthly_info: home.ar.monthly_info,
          yearly_info: home.ar.yearly_info,

          freePlan_is_used: home.ar.freePlan_is_used,
          freePlan_succesfuly_Actived: home.ar.freePlan_succesfuly_Actived,
          actived_plan: home.ar.actived_plan,
          fib_info: home.ar.fib_info,

          payment_important: home.ar.payment_important,
          payment_monyBack: home.ar.payment_monyBack,

          business_account: home.ar.business_account,
          Personal_account: home.ar.Personal_account,

          Buy_Now: home.ar.Buy_Now,
          buy_help: home.ar.buy_help,

          Log_out: home.ar.Log_out,

          Payment_Successful: home.ar.Payment_Successful,
          Payment_failed: home.ar.Payment_failed,
          pricing: home.ar.pricing,
          pricing_plan: home.ar.pricing_plan,
          Choose_your_plan: home.ar.Choose_your_plan,
          Pricing_content: home.ar.Pricing_content,
          Free: home.ar.Free,
          Free_content: home.ar.Free_content,
          Monthly: home.ar.Monthly,
          Monthly_content: home.ar.Monthly_content,
          Yearly: home.ar.Yearly,
          Yearly_content: home.ar.Yearly_content,
          paid: home.ar.paid,
          Starter: home.ar.Starter,
          Most_popular: home.ar.Most_popular,

          //jobs
          Apply_now: jobs.ar.Apply_now,

          job_byTitle: jobs.ar.job_byTitle,
          job_byFunctional_area: jobs.ar.job_byFunctional_area,
          Jobs_By_Industry: jobs.ar.Jobs_By_Industry,
          Jobs_By_Skill: jobs.ar.Jobs_By_Skill,
          Jobs_By_Country: jobs.ar.Jobs_By_Country,
          Jobs_By_CompanyName: jobs.ar.Jobs_By_CompanyName,
          Jobs_By_City: jobs.ar.Jobs_By_City,
          expired: jobs.ar.expired,
          Period: jobs.ar.Period,
          add_job: jobs.ar.add_job,
          Jobs_By_type: jobs.ar.Jobs_By_type,
          Jobs_By_state: jobs.ar.Jobs_By_state,
          Jobs_By_Experience: jobs.ar.Jobs_By_Experience,
          Jobs_By_degree: jobs.ar.Jobs_By_degree,
          company_name: jobs.ar.company_name,
          gender: jobs.ar.gender,
          female: jobs.ar.female,
          male: jobs.ar.male,


          
          years_ago: jobs.ar.years_ago,
          months_ago: jobs.ar.months_ago,
          days_ago: jobs.ar.days_ago,
          hours_ago: jobs.ar.hours_ago,
          seconds_ago: jobs.ar.seconds_ago,
          minutes_ago: jobs.ar.minutes_ago,
         
          since: jobs.ar.since,

//single date
          year_ago: jobs.ar.year_ago,
          month_ago: jobs.ar.month_ago,
          day_ago: jobs.ar.day_ago,
          hour_ago: jobs.ar.hour_ago,
          second_ago: jobs.ar.second_ago,
          minute_ago: jobs.ar.minute_ago,



          //job details
          Job_Title: jobs.ar.Job_Title,
          Job_Type: jobs.ar.Job_Type,
          Experience: jobs.ar.Experience,
          Salary: jobs.ar.Salary,
          Address: jobs.ar.Address,
          Expire_Date: jobs.ar.Expire_Date,
          Industry: jobs.ar.Industry,
          Contact_Information: jobs.ar.Contact_Information,
          job_details: jobs.ar.job_details,
          update: jobs.ar.update,
          delete: jobs.ar.delete,
          //servises
          update_service: services.ar.update_service,

          order_now: services.ar.order_now,
          Cancel: services.ar.Cancel,

          Cleaning_the_house: services.ar.Cleaning_the_house,
          Vehicle_Maintenance: services.ar.Vehicle_Maintenance,
          Electrical_Repairs: services.ar.Electrical_Repairs,
          Freight_Forwarding: services.ar.Freight_Forwarding,
          Home_ApplianceRepairs: services.ar.Home_ApplianceRepairs,
          Garden_Maintenance: services.ar.Garden_Maintenance,
          CoolingHeating: services.ar.CoolingHeating,
          Car_Wash: services.ar.Car_Wash,
          Electronic_Services: services.ar.Electronic_Services,
          Duct_Cleaning: services.ar.Duct_Cleaning,
          Skincare_Treatments: services.ar.Skincare_Treatments,
          Makeup: services.ar.Makeup,
          Nail: services.ar.Nail,
          Veterinary: services.ar.Veterinary,
          Hairdressing: services.ar.Hairdressing,
          Mortgage: services.ar.Mortgage,
          Legal_Consultation: services.ar.Legal_Consultation,
          Photography: services.ar.Photography,
          Tire_Replacement: services.ar.Tire_Replacement,
          Battery_Services: services.ar.Battery_Services,
          construction_of_houses: services.ar.construction_of_houses,
          Post: services.ar.Post,
          Carpet: services.ar.Carpet,
          Travel_Tourism: services.ar.Travel_Tourism,

          //add services
          Add_service: services.ar.Add_service,
          services_list: services.ar.services_list,
          my_services: services.ar.my_services,
          Category: services.ar.Category,
          delevery: services.ar.delevery,
          price: services.ar.price,
          error: services.ar.error,
          image: services.ar.image,
          choose_a_photo: services.ar.choose_a_photo,
          State: services.ar.State,
          select_country: services.ar.select_country,
          select_state: services.ar.select_state,
          select_city: services.ar.select_city,
          select_currency: services.ar.select_currency,
          Title: services.ar.Title,
          not_haveData: services.ar.not_haveData,
          not_haveServices: services.ar.not_haveServices,
          available: services.ar.available,
          not_available: services.ar.not_available,

          service_title: services.ar.service_title,
          service_type: services.ar.service_type,
          service_detail: services.ar.service_detail,

          //home headers
          CV_templates: home.ar.CV_templates,
          Jobs: home.ar.Jobs,
          Freelancers: home.ar.Freelancers,
          lecturers: home.ar.lecturers,
          Services: home.ar.Services,
          //lecturers
          name: lecturers.ar.name,
          language: lecturers.ar.language,
          duration: lecturers.ar.duration,
          study_mode: lecturers.ar.study_mode,
          numberOf_years_teaching: lecturers.ar.numberOf_years_teaching,
          grade_level: lecturers.ar.grade_level,
          Subject: lecturers.ar.Subject,
          Certifications: lecturers.ar.Certifications,
          language: lecturers.ar.language,
          duration: lecturers.ar.duration,
          study_mode: lecturers.ar.study_mode,
          numberOf_years_teaching: lecturers.ar.numberOf_years_teaching,
          grade_level: lecturers.ar.grade_level,
          Subject: lecturers.ar.Subject,
          Kurdish: lecturers.ar.Kurdish,
          Arabic: lecturers.ar.Arabic,
          English: lecturers.ar.English,
          math: lecturers.ar.math,
          science: lecturers.ar.science,
          chemistry: lecturers.ar.chemistry,
          Physics: lecturers.ar.Physics,
          Biology: lecturers.ar.Biology,
          computer: lecturers.ar.computer,
          economy: lecturers.ar.economy,
          Primary_School: lecturers.ar.Primary_School,
          Middle_School: lecturers.ar.Middle_School,
          High_School: lecturers.ar.High_School,
          add_lecturer: lecturers.ar.add_lecturer,
          lecturer_detail: lecturers.ar.lecturer_detail,

          bachelors: lecturers.ar.bachelors,
          master: lecturers.ar.master,
          Doctorate: lecturers.ar.Doctorate,

          Primary_School_lecturer_list:
            lecturers.ar.Primary_School_lecturer_list,
          Middle_School_lecturer_list: lecturers.ar.Middle_School_lecturer_list,
          High_School_lecturer_list: lecturers.ar.High_School_lecturer_list,
          my_lecturer_profile: lecturers.ar.my_lecturer_profile,
          update_Lecturer: lecturers.ar.update_Lecturer,

          primary_header: lecturers.ar.primary_header,
          primary_content: lecturers.ar.primary_content,

          middle_header: lecturers.ar.middle_header,
          middle_content: lecturers.ar.middle_content,

          High_header: lecturers.ar.High_header,
          High_content: lecturers.ar.High_content,

          Update_your_profile: lecturers.ar.Update_your_profile,

          //auth
          Verify_Email: auth.ar.Verify_Email,
          Email_Verification: auth.ar.Email_Verification,
          Verify_your_Email: auth.ar.Verify_your_Email,

          Sign_account: auth.ar.Sign_account,
          Email_not_found: auth.ar.Email_not_found,
          Email_required: auth.ar.Email_required,
          Incorrect_password: auth.ar.Incorrect_password,
          Password_required: auth.ar.Password_required,
          Forgot_password: auth.ar.Forgot_password,
          Sign_in: auth.ar.Sign_in,
          Or: auth.ar.Or,
          Login_with_Google: auth.ar.Login_with_Google,
          Login_with_Facebook: auth.ar.Login_with_Facebook,
          Dont_have_account: auth.ar.Dont_have_account,
          email: auth.ar.email,
          Password: auth.ar.Password,
          Sign_up: auth.ar.Sign_up,

          name_required: auth.ar.name_required,
          Sign_up_register: auth.ar.Sign_up_register,
          email_already_taken: auth.ar.email_already_taken,
          password_not_match: auth.ar.password_not_match,
          password_atLeast: auth.ar.password_atLeast,

          Sign_up_button: auth.ar.Sign_up_button,
          have_acount: auth.ar.have_acount,
          Confirm_Password: auth.ar.Confirm_Password,

          Email_Verification: auth.ar.Email_Verification,
          verify_header: auth.ar.verify_header,
          Invalid_verification: auth.ar.Invalid_verification,

          Verify_account: auth.ar.Verify_account,
          dont_receive_code: auth.ar.dont_receive_code,
          verified_successfully: auth.ar.verified_successfully,
          Resended_code: auth.ar.Resended_code,
          //filter by
          Filtering_by: jobs.ar.Filtering_by,

          Resend: auth.ar.Resend,
          Password_reset_successful: auth.ar.Password_reset_successful,
          Reset_Password: auth.ar.Reset_Password,
          send_reset_link: auth.ar.send_reset_link,
          password_confirm: auth.ar.password_confirm,
          login_successful: auth.ar.login_successful,

          //error page
          errorPage: error404.ar.errorPage,
          Page_not_found: error404.ar.Page_not_found,
          buttonHome: error404.ar.buttonHome,
          buttonContact_support: error404.ar.buttonContact_support,
        },
      },
      ku: {
        translation: {
          //long cv
          "long cv title": longCV.ku.title,
          "main header long cv": longCV.ku.main,
          "long text1": longCV.ku.text1,
          "long text2": longCV.ku.text2,
          "long text3": longCV.ku.text3,
          "long text4": longCV.ku.text4,
          "long text5": longCV.ku.text5,

            //navbar
Sections: home.ku.Sections,

          //cv
          sub_title: cv.ku.sub_title,
          Create_CV: cv.ku.Create_CV,

          summary: cv.ku.summary,
          Contact: cv.ku.Contact,
          Education: cv.ku.Education,
          Add_Education: cv.ku.Add_Education,
          School: cv.ku.School,
          City: cv.ku.City,
          Start_date: cv.ku.Start_date,
          End_date: cv.ku.End_date,
          Please_log_in: cv.ku.Please_log_in,

          level: cv.ku.level,
          Title: cv.ku.Title,
          Course: cv.ku.Course,
          Add_Course: cv.ku.Add_Course,
          Work_experience: cv.ku.Work_experience,
          add_Work_experience: cv.ku.add_Work_experience,
          Add_skills: cv.ku.Add_skills,
          Add_Language: cv.ku.Add_Language,
          image_select: cv.ku.image_select,
          select_file: cv.ku.select_file,

          //freelancer add
          Top_Freelancers_search: freelancer_add.ku.Top_Freelancers_search,

          Reviews: freelancer_add.ku.Reviews,
          My_Review: freelancer_add.ku.My_Review,
          comment: freelancer_add.ku.comment,

          one_to_3_years: freelancer_add.ku.one_to_3_years,
          four_to_6_years: freelancer_add.ku.four_to_6_years,
          more_than_6: freelancer_add.ku.more_than_6,

          freelancer_Name: freelancer_add.ku.freelancer_Name,
          freelancer_update: freelancer_add.ku.freelancer_update,
          freelancer_profile: freelancer_add.ku.freelancer_profile,
          phone_number: freelancer_add.ku.phone_number,
          freelance_details: freelancer_add.ku.freelance_details,

          Email: freelancer_add.ku.Email,
          Skills: freelancer_add.ku.Skills,
          Country: freelancer_add.ku.Country,
          City: freelancer_add.ku.City,
          Functional_Area: freelancer_add.ku.Functional_Area,
          currency: freelancer_add.ku.currency,
          Description: freelancer_add.ku.Description,
          send: freelancer_add.ku.send,
          Make_sure_you_fill_out_all_the_information_correctly:
            freelancer_add.ku
              .Make_sure_you_fill_out_all_the_information_correctly,
          Add_freelance: freelancer_add.ku.Add_freelance,
          Created_successfully: freelancer_add.ku.Created_successfully,
          Finding_a_job: freelancer_add.ku.Finding_a_job,
          freelancers_list: freelancer_add.ku.freelancers_list,
          per_hour_price: freelancer_add.ku.per_hour_price,
          experience: freelancer_add.ku.experience,
          Updated_successfully: freelancer_add.ku.Updated_successfully,
          update_freelancer: freelancer_add.ku.update_freelancer,

          Show_all: home.ku.Show_all,

          //cv info
          What_isCV_content: home.ku.What_isCV_content,
          How_writeCV_content: home.ku.How_writeCV_content,
          purpose_cv_content: home.ku.purpose_cv_content,
          type_cv_content: home.ku.type_cv_content,

          //footer
          Resources: home.ku.Resources,
          Contact_us: home.ku.Contact_us,
          About_us: home.ku.About_us,
          social_media: home.ku.social_media,
          language: home.ku.language,
          All_rights_reserved: home.ku.All_rights_reserved,
          get_started: home.ku.get_started,
          Support: home.ku.Support,
          Developed_by: home.ku.Developed_by,

          //main
          freelancer: freelancer_add.ku.freelancer,
          search: freelancer_add.ku.search,
          add_freelancer: freelancer_add.ku.add_freelancer,
          freelancer_not_found: freelancer_add.ku.freelancer_not_found,
          address: freelancer_add.ku.address,
          years: freelancer_add.ku.years,
          //home how it works
          how_works_create_acount: home.ku.how_works_create_acount,
          how_works_create_acount_content:
            home.ku.how_works_create_acount_content,
          how_works_select_template: home.ku.how_works_select_template,
          how_works_select_template_content:
            home.ku.how_works_select_template_content,
          how_works_create_cv: home.ku.how_works_create_cv,
          how_works_create_cv_content: home.ku.how_works_create_cv_content,
          how_works_find_job: home.ku.how_works_find_job,
          how_works_find_job_content: home.ku.how_works_find_job_content,
          Step1: home.ku.Step1,
          Step2: home.ku.Step2,
          Step3: home.ku.Step3,
          Step4: home.ku.Step4,

          //headder
          header_main_text: home.ku.header_main_text,
          header_sub_main_text: home.ku.header_sub_main_text,
          Create_resume: home.ku.Create_resume,
          Find_Job: home.ku.Find_Job,
          welcome_to: home.ku.welcome_to,
          header_main_text1: home.ku.header_main_text,
          header_main_text2: home.ku.header_main_text2,

          name_required: auth.ku.name_required,
          Sign_up_register: auth.ku.Sign_up_register,
          email_already_taken: auth.ku.email_already_taken,
          password_not_match: auth.ku.password_not_match,
          password_atLeast: auth.ku.password_atLeast,
          Verify_Email: auth.ku.Verify_Email,
          Email_Verification: auth.ku.Email_Verification,
          Verify_your_Email: auth.ku.Verify_your_Email,

          //cv articles
          whatis_content: longCV.ku.whatis_content,
          whatis_header: longCV.ku.whatis_header,
          cv_howTo_write_content: longCV.ku.cv_howTo_write_content,
          cv_howTo_write_header: longCV.ku.cv_howTo_write_header,
          cv_purpose_content: longCV.ku.cv_purpose_content,
          cv_purpose_header: longCV.ku.cv_purpose_header,

          type_main: longCV.ku.type_main,
          Chronological_CV: longCV.ku.Chronological_CV,
          types_there: longCV.ku.types_there,

          other_types: longCV.ku.other_types,
          Europass_cv: longCV.ku.Europass_cv,
          academic_cv: longCV.ku.academic_cv,
          skill_based: longCV.ku.skill_based,
          creative_cv: longCV.ku.creative_cv,

          skill_based_header: longCV.ku.skill_based_header,
          other_header: longCV.ku.other_header,
          europas_header: longCV.ku.europas_header,
          Academic_header: longCV.ku.Academic_header,
          creative_header: longCV.ku.creative_header,

          //about
          about_cv_top_text: home.ku.about_cv_top_text,
          about_cv_bottom_text: home.ku.about_cv_bottom_text,
          about_cv_main_text: home.ku.about_cv_main_text,
          Home: home.ku.Home,

          What_isCV: home.ku.What_isCV,
          How_writeCV: home.ku.How_writeCV,
          purpose_cv: home.ku.purpose_cv,
          header_main_text2: home.ku.header_main_text2,
          type_cv: home.ku.type_cv,

          //about us
          Our_Mission: home.ku.Our_Mission,
          Our_Mission_content: home.ku.Our_Mission_content,
          Our_Values: home.ku.Our_Values,
          Innovation: home.ku.Innovation,
          Innovation_content: home.ku.Innovation_content,
          Integrity: home.ku.Integrity,
          Integrity_content: home.ku.Integrity_content,
          Customer_Centricity: home.ku.Customer_Centricity,
          Customer_Centricity_content: home.ku.Customer_Centricity_content,
          contact_content: home.ku.contact_content,


          manager: home.ku.manager,
          manager_description: home.ku.manager_description,
          manager_role: home.ku.manager_role,



          ceate_cv_content: home.ku.ceate_cv_content,
          jobs_content: home.ku.jobs_content,
          freelance_content: home.ku.freelance_content,
          services_content: home.ku.services_content,
          lecturers_content: home.ku.lecturers_content,

          What_We_Offer: home.ku.What_We_Offer,

          //pricing
          //info
          free_info: home.ku.free_info,
          monthly_info: home.ku.monthly_info,

          freePlan_is_used: home.ku.freePlan_is_used,
          freePlan_succesfuly_Actived: home.ku.freePlan_succesfuly_Actived,
          actived_plan: home.ku.actived_plan,
          fib_info: home.ku.fib_info,
          payment_important: home.ku.payment_important,
          payment_monyBack: home.ku.payment_monyBack,

          Payment_Successful: home.ku.Payment_Successful,
          Payment_failed: home.ku.Payment_failed,

          Buy_Now: home.ku.Buy_Now,
          buy_help: home.ku.buy_help,

          Log_out: home.ku.Log_out,

          business_account: home.ku.business_account,
          Personal_account: home.ku.Personal_account,
          yearly_info: home.ku.yearly_info,
          pricing: home.ku.pricing,
          Starter: home.ku.Starter,

          pricing_plan: home.ku.pricing_plan,
          Choose_your_plan: home.ku.Choose_your_plan,
          Pricing_content: home.ku.Pricing_content,
          Free: home.ku.Free,
          Free_content: home.ku.Free_content,
          Monthly: home.ku.Monthly,
          Monthly_content: home.ku.Monthly_content,
          Yearly: home.ku.Yearly,
          Yearly_content: home.ku.Yearly_content,
          paid: home.ku.paid,
          Most_popular: home.ku.Most_popular,

          //home headers
          CV_templates: home.ku.CV_templates,
          Jobs: home.ku.Jobs,
          Freelancers: home.ku.Freelancers,
          lecturers: home.ku.lecturers,
          Services: home.ku.Services,

          //jobs
          Apply_now: jobs.ku.Apply_now,

          job_byTitle: jobs.ku.job_byTitle,
          job_byFunctional_area: jobs.ku.job_byFunctional_area,
          Jobs_By_Industry: jobs.ku.Jobs_By_Industry,
          Jobs_By_Skill: jobs.ku.Jobs_By_Skill,
          Jobs_By_Country: jobs.ku.Jobs_By_Country,
          Jobs_By_City: jobs.ku.Jobs_By_City,
          Jobs_By_CompanyName: jobs.ku.Jobs_By_CompanyName,
          expired: jobs.ku.expired,
          company_name: jobs.ku.company_name,

          gender: jobs.ku.gender,
          female: jobs.ku.female,
          male: jobs.ku.male,

          Period: jobs.ku.Period,
          add_job: jobs.ku.add_job,
          update: jobs.ku.update,
          delete: jobs.ku.delete,

          Jobs_By_type: jobs.ku.Jobs_By_type,
          Jobs_By_state: jobs.ku.Jobs_By_state,
          Jobs_By_Experience: jobs.ku.Jobs_By_Experience,
          Jobs_By_degree: jobs.ku.Jobs_By_degree,


          years_ago: jobs.ku.years_ago,
          months_ago: jobs.ku.months_ago,
          days_ago: jobs.ku.days_ago,
          hours_ago: jobs.ku.hours_ago,
          seconds_ago: jobs.ku.seconds_ago,
          minutes_ago: jobs.ku.minutes_ago,
         
          //for single date



          
          year_ago: jobs.ku.year_ago,
          month_ago: jobs.ku.month_ago,
          day_ago: jobs.ku.day_ago,
          hour_ago: jobs.ku.hour_ago,
          second_ago: jobs.ku.second_ago,
          minute_ago: jobs.ku.minute_ago,


          //job details
          Job_Title: jobs.ku.Job_Title,
          Job_Type: jobs.ku.Job_Type,
          Experience: jobs.ku.Experience,
          Salary: jobs.ku.Salary,
          Address: jobs.ku.Address,
          Expire_Date: jobs.ku.Expire_Date,
          Industry: jobs.ku.Industry,
          Contact_Information: jobs.ku.Contact_Information,
          job_details: jobs.ku.job_details,

          //servises
          update_service: services.ku.update_service,
          order_now: services.ku.order_now,
          Cancel: services.ku.Cancel,
          Cleaning_the_house: services.ku.Cleaning_the_house,
          Vehicle_Maintenance: services.ku.Vehicle_Maintenance,
          Electrical_Repairs: services.ku.Electrical_Repairs,
          Freight_Forwarding: services.ku.Freight_Forwarding,
          Home_ApplianceRepairs: services.ku.Home_ApplianceRepairs,
          Garden_Maintenance: services.ku.Garden_Maintenance,
          CoolingHeating: services.ku.CoolingHeating,
          Car_Wash: services.ku.Car_Wash,
          Electronic_Services: services.ku.Electronic_Services,
          Duct_Cleaning: services.ku.Duct_Cleaning,
          Skincare_Treatments: services.ku.Skincare_Treatments,
          Makeup: services.ku.Makeup,
          Nail: services.ku.Nail,
          Veterinary: services.ku.Veterinary,
          Carpet: services.ku.Carpet,
          Hairdressing: services.ku.Hairdressing,
          Mortgage: services.ku.Mortgage,
          Legal_Consultation: services.ku.Legal_Consultation,
          Photography: services.ku.Photography,
          Tire_Replacement: services.ku.Tire_Replacement,
          Battery_Services: services.ku.Battery_Services,
          construction_of_houses: services.ku.construction_of_houses,
          Travel_Tourism: services.ku.Travel_Tourism,

          Post: services.ku.Post,
          //add services
          Add_service: services.ku.Add_service,
          services_list: services.ku.services_list,
          my_services: services.ku.my_services,
          Category: services.ku.Category,
          delevery: services.ku.delevery,
          price: services.ku.price,
          error: services.ku.error,
          image: services.ku.image,
          choose_a_photo: services.ku.choose_a_photo,
          State: services.ku.State,
          select_country: services.ku.select_country,
          select_state: services.ku.select_state,
          select_city: services.ku.select_city,
          select_currency: services.ku.select_currency,
          Title: services.ku.Title,
          not_haveData: services.ku.not_haveData,
          not_haveServices: services.ku.not_haveServices,
          available: services.ku.available,
          not_available: services.ku.not_available,

          service_title: services.ku.service_title,
          service_type: services.ku.service_type,
          service_detail: services.ku.service_detail,

          //filter by
          Filtering_by: jobs.ku.Filtering_by,

          //lecturers
          name: lecturers.ku.name,
          language: lecturers.ku.language,
          duration: lecturers.ku.duration,
          study_mode: lecturers.ku.study_mode,
          numberOf_years_teaching: lecturers.ku.numberOf_years_teaching,
          grade_level: lecturers.ku.grade_level,
          Subject: lecturers.ku.Subject,
          Certifications: lecturers.ku.Certifications,
          Kurdish: lecturers.ku.Kurdish,
          Arabic: lecturers.ku.Arabic,
          English: lecturers.ku.English,
          math: lecturers.ku.math,
          science: lecturers.ku.science,
          chemistry: lecturers.ku.chemistry,
          Physics: lecturers.ku.Physics,
          Biology: lecturers.ku.Biology,
          computer: lecturers.ku.computer,
          economy: lecturers.ku.economy,
          Primary_School: lecturers.ku.Primary_School,
          Middle_School: lecturers.ku.Middle_School,
          High_School: lecturers.ku.High_School,
          add_lecturer: lecturers.ku.add_lecturer,
          lecturer_detail: lecturers.ku.lecturer_detail,
          bachelors: lecturers.ku.bachelors,
          master: lecturers.ku.master,
          Doctorate: lecturers.ku.Doctorate,

          primary_header: lecturers.ku.primary_header,
          primary_content: lecturers.ku.primary_content,

          middle_header: lecturers.ku.middle_header,
          middle_content: lecturers.ku.middle_content,

          High_header: lecturers.ku.High_header,
          High_content: lecturers.ku.High_content,
          Update_your_profile: lecturers.ku.Update_your_profile,

          Primary_School_lecturer_list:
            lecturers.ku.Primary_School_lecturer_list,
          Middle_School_lecturer_list: lecturers.ku.Middle_School_lecturer_list,
          High_School_lecturer_list: lecturers.ku.High_School_lecturer_list,
          my_lecturer_profile: lecturers.ku.my_lecturer_profile,
          update_Lecturer: lecturers.ku.update_Lecturer,

          //auth
          Sign_account: auth.ku.Sign_account,
          Email_not_found: auth.ku.Email_not_found,
          Email_required: auth.ku.Email_required,
          Incorrect_password: auth.ku.Incorrect_password,
          Password_required: auth.ku.Password_required,
          Forgot_password: auth.ku.Forgot_password,
          Sign_in: auth.ku.Sign_in,
          Or: auth.ku.Or,
          Login_with_Google: auth.ku.Login_with_Google,
          Login_with_Facebook: auth.ku.Login_with_Facebook,
          Dont_have_account: auth.ku.Dont_have_account,
          email: auth.ku.email,
          Password: auth.ku.Password,
          Sign_up: auth.ku.Sign_up,

          Sign_up_button: auth.ku.Sign_up_button,
          have_acount: auth.ku.have_acount,
          Confirm_Password: auth.ku.Confirm_Password,

          Email_Verification: auth.ku.Email_Verification,
          verify_header: auth.ku.verify_header,
          Invalid_verification: auth.ku.Invalid_verification,

          Verify_account: auth.ku.Verify_account,
          dont_receive_code: auth.ku.dont_receive_code,
          verified_successfully: auth.ku.verified_successfully,

          Resend: auth.ku.Resend,
          Resended_code: auth.ku.Resended_code,
          password_confirm: auth.ku.password_confirm,

          Password_reset_successful: auth.ku.Password_reset_successful,
          Reset_Password: auth.ku.Reset_Password,
          send_reset_link: auth.ku.send_reset_link,

          login_successful: auth.ku.login_successful,

          //error page
          errorPage: error404.ku.errorPage,
          Page_not_found: error404.ku.Page_not_found,
          buttonHome: error404.ku.buttonHome,
          buttonContact_support: error404.ku.buttonContact_support,
        },
      },
    },

    lng: "en",
    fallbackLng: "en",
    detection: {
      order: [
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["cookie", "localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Navbar = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const drawerRef = React.useRef(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const closeDrawer = () => {
    // Use this function to close the drawer
    drawerRef.current?.setAttribute("aria-hidden", "true");
    drawerRef.current?.classList.add("hidden");
  };

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());

      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const user_role = useSelector((state) => state.cv.user.role) || "";

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu when clicking outside the menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const { t, i18n } = useTranslation();

  const submittt = async () => {
    await dispatch(updateProperty(["as", "sxassx"]));
  };
  const hideDrawer = () => {
    document.getElementById("drawer-top-example").style.transform =
      "translateY(-100%)";
  };
  const token = useSelector((state) => state.cv.isAuthuntucated);

  return (
    <div className="fixed top-0 w-full nav-box  z-50 bg-white dark:bg-gray-800 shadow-md dark:border-gray-600">
      {/* Navbar Header */}
      <nav className="relative px-4 text-[17px] py-2.5 flex justify-between items-center bg-white dark:bg-gray-800 dark:border-gray-600">
        <Link to={"/"} className=" font-bold mt-[5px] text-[#ffb923] dark:text-white">
          <img
            src={brand}
            alt="brand"
            title="brand"
            className=" sm:h-[40px] h-[25px]"
          />
        </Link>

        {/* Mobile Menu Button */}
        <div className="phone-toggle  ">
          <div
            id="theme-toggle"
            className="  mt-2 lg:ml-auto py-1.5 px-3 m-1 text-center "
          >
            {token}
            <Lang_swith />
          </div>
          {token ? (
            <div>
              <span className="hidden" id="util_data"></span>
              <Link
                onClick={async () => {
                  await Cookies.remove("jwt"); // Replace 'jwt' with the actual name of your JWT cookie
                  window.location.href = "/login";
                }}
                className="py-1.5 px-2.5 text-[13px] sm:text-[16px] no-underline m-1 text-center   hover:bg-gray-50 hover:text-gray-700  border border-gray-300 rounded-md text-black hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-700 "
              >
                {t("Log_out")}{" "}
              </Link>
            </div>
          ) : (
            <div>
              <span className="hidden" id="util_data"></span>
              <Link
                to={token ? "/" : "/login"}
                className="py-1.5 px-2.5 text-[13px] sm:text-[16px] no-underline m-1 text-center  border border-gray-300 rounded-md text-black hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-700 "
              >
                {t("Sign_in")}{" "}
              </Link>
            </div>
          )}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </div>

        {/* Search Bar (Desktop) */}
        <ul
          onClick={closeDrawer}
          style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }}
          className={`absolute  top-1/2 left-1/2 transform  flex gap-4 -translate-y-1/2 -translate-x-1/2 pr-[130px]  lg:mx-auto cv-designs-desktop  lg:items-center  `}
        >
          <Link
            className="hover:text-gray-600 no-underline text-gray-800 "
            to={"/"}
          >
            {t("Home")}
          </Link>{" "}
          <Link
            className="hover:text-gray-600 no-underline whitespace-nowrap text-gray-800 "
            to={"/CV-templates"}
          >
            {t("Create_CV")}
          </Link>
          <Link
            className="hover:text-gray-600 no-underline text-gray-800 whitespace-nowrap "
            to={"/jobs"}
          >
            {t("Jobs")}
          </Link>
          <Sections />
          <Link to={'/About-us'}
            className="hover:text-gray-600 no-underline text-gray-800  whitespace-nowrap "
          >
                    {t("About_us")}
                    </Link>{" "}
                    <Link
                    to={"/Contact-us"}
                    className=" hover:text-gray-600 no-underline text-gray-800  whitespace-nowrap "
                  >
                    {t("Contact_us")}
                  </Link>
          
        </ul>

        {/* Utility Buttons (Desktop) */}
        <div className=" flex  desktop-navbar">
          <div
            id="theme-toggle"
            className="hidden mt-2 lg:inline-block lg:ml-auto py-1.5 px-3 m-1 text-center "
          >
            <Lang_swith />
          </div>

          <Link
            to={"/jobs"}
            className="py-2 px-3 m-1 text-center no-underline bg-[#ffb923]  rounded-md text-white hover:bg-[#ffc64a] hover:text-gray-100"
          >
            {t("get_started")}{" "}
          </Link>
          {token ? (
            <div>
              <span className="hidden" id="util_data"></span>
              <Link
                onClick={async () => {
                  await Cookies.remove("jwt"); // Replace 'jwt' with the actual name of your JWT cookie
                  window.location.href = "/login";
                }}
                to={"/"}
                className="py-2 px-3 no-underline m-1 text-center bg-gray-100 border border-gray-300  rounded-md text-black hover:bg-gray-50 hover:text-gray-700 dark:text-gray-300 dark:bg-gray-700 hidden lg:inline-block"
              >
                {t("Log_out")}{" "}
              </Link>
            </div>
          ) : (
            <div className={token ? "hidden" : null}>
              <span></span>
              <Link
                to={token ? "/" : "/login"}
                className="py-2 px-3 no-underline m-1 text-center bg-gray-100 border border-gray-300 rounded-md text-black hover:bg-gray-100 dark:text-gray-300 dark:bg-gray-700 hidden lg:inline-block"
              >
                {t("Sign_in")}{" "}
              </Link>
            </div>
          )}
        </div>
      </nav>

      {/* Overlay when menu is open (for mobile) */}

      {/* Mobile Menu Content */}

      <div
        ref={drawerRef}
        style={{
          direction:
            i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
        }}
        id="drawer-top-example"
        className="fixed top-0 left-0 right-0 z-40 w-full p-4 transition-transform -translate-y-full bg-white dark:bg-gray-800"
        tabIndex="-1"
        aria-labelledby="drawer-top-label"
      >
        <div className="flex item-center justify-between">
          <h5
            id="drawer-top-label"
            className="inline-flex items-center  text-base font-semibold text-gray-500 dark:text-gray-400"
          >
            <svg
              className="w-4 h-4 me-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            Top drawer
          </h5>

          <button
            type="button"
            data-drawer-hide="drawer-top-example"
            aria-controls="drawer-top-example"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 "
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
        </div>

        <hr className="my-[20px]" />
        {user_role === "admin" ? (
          <div className="  py-2"></div>
        ) : (
          <ul className="flex flex-col gap-3 text-[17px]">
            <Link onClick={closeDrawer} to={"/"}>
              {t("home")}
            </Link>{" "}
            <Link
              className="hover:text-[#ffb923] no-underline text-gray-800 "
              to={"/jobs"}
            >
              {t("Jobs")}
            </Link>
            <Link
              className="hover:text-[#ffb923] no-underline text-gray-800 "
              to={"/freelance"}
            >
              {t("Freelancers")}
            </Link>{" "}
            <Link
              className="hover:text-[#ffb923] no-underline text-gray-800 "
              to={"/teachers"}
            >
              {t("lecturers")}
            </Link>{" "}
            <Link
              className="hover:text-[#ffb923] no-underline text-gray-800 "
              to={"/Services"}
            >
              {t("Services")}
            </Link>
          </ul>
        )}
      </div>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {/* Black overlay when drawer is open */}
        {open && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
              zIndex: 1200, // Just below the drawer, which is at 1300
            }}
            onClick={handleDrawerClose} // Close drawer when overlay is clicked
          />
        )}

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {user_role === "admin" ? (
            <List
              style={{
                direction:
                  i18n.language === "ku" || i18n.language === "ar"
                    ? "rtl"
                    : "ltr",
              }}
              onClick={handleDrawerClose}
            >
              {[
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/Admin-panel/jobs"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Jobs                    </ListItemButton>
                  </ListItem>
                </Link>,
                ,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/Admin-panel/jobs/insert"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Add Job
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800"
                  to={"/Admin-panel/freelance"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    freelancers                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800"
                  to={"/Admin-panel/freelance/insert"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Add freelancer                    </ListItemButton>
                  </ListItem>
                </Link>,

                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800"
                  to={"/Admin-panel/Primary-Lecturer"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Primary School
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800"
                  to={"/Admin-panel/Middle-Lecturer"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Middle School{" "}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800"
                  to={"/Admin-panel/High-Lecturer"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    High School{" "}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800"
                  to={"/Admin-panel/Lecturer/insert"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Add lecturer{" "}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/Admin-panel/services"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Services list{" "}
                    </ListItemButton>
                  </ListItem>
                </Link>,

                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/Admin-panel/services/insert"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Add service
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/Admin-panel/users"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                    Users{" "}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                   <Link
                   className="hover:text-[#ffb923] no-underline text-gray-800 "
                   to={"/Admin-panel/order"}
                   >
                   <ListItem disablePadding>
                     <ListItemButton className="min-w-full hover:text-[#ffb923]">
                     Order{" "}
                     </ListItemButton>
                   </ListItem>
                 </Link>,
                    <Link
                    className="hover:text-[#ffb923] no-underline text-gray-800 "
                    to={"/Admin-panel/Refound"}
                    >
                    <ListItem disablePadding>
                      <ListItemButton className="min-w-full hover:text-[#ffb923]">
                      Payment Refund{" "}
                      </ListItemButton>
                    </ListItem>
                  </Link>,
              ].map((text, index) => (
                <ListItemText
                  key={index}
                  className="min-w-full"
                  primary={text}
                />
              ))}
            </List>
          ) : (
            <List
              style={{
                direction:
                  i18n.language === "ku" || i18n.language === "ar"
                    ? "rtl"
                    : "ltr",
              }}
              onClick={handleDrawerClose}
            >
              {[
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                      {t("Home")}
                    </ListItemButton>
                  </ListItem>
                </Link>,

                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/CV-templates"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                      {t("Create_CV")}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/jobs"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                      {t("Jobs")}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/freelance"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                      {t("Freelancers")}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/teachers"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                      {t("lecturers")}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                <Link
                  className="hover:text-[#ffb923] no-underline text-gray-800 "
                  to={"/Services"}
                >
                  <ListItem disablePadding>
                    <ListItemButton className="min-w-full hover:text-[#ffb923]">
                      {t("Services")}{" "}
                    </ListItemButton>
                  </ListItem>
                </Link>,
                   <Link
                   className="hover:text-[#ffb923] no-underline text-gray-800 "
                   to={"/About-us"}
                 >
                   <ListItem disablePadding>
                     <ListItemButton className="min-w-full hover:text-[#ffb923]">
                     {t("About_us")}
                     </ListItemButton>
                   </ListItem>
                 </Link>,
                    <Link
                    className="hover:text-[#ffb923] no-underline text-gray-800 "
                    to={"/Contact-us"}
                  >
                    <ListItem disablePadding>
                      <ListItemButton className="min-w-full hover:text-[#ffb923]">
                      {t("Contact_us")}
                      </ListItemButton>
                    </ListItem>
                  </Link>,
              ].map((text, index) => (
                <ListItemText
                  key={index}
                  className="min-w-full"
                  primary={text}
                />
              ))}
            </List>
          )}
        </Drawer>
      </Box>
    </div>
  );
};

export default Navbar;
/*en: {
              translation: {
                "Welcome to React": "Welcome to React and react-i18next",
                "jobs":"jobs",
                "home":"home",
                "About us":"About us",
                "Contact us":"contact us",
                "create cv" :"create cv",
                "language":"language",
              }
            },
              ar: {
              translation: {
                "jobs":"وضائف",
                "home":"الصحفة الرئيسية",
                "About us":"من نحن",
                "Contact us":"اتصل بنا",
                "create cv" :"create cv",
                "language":"اللغة",
              }
            },*/
