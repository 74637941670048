import React, { useEffect } from "react";
import Design from "./input-create-cv";
import Cv1_content from "../../components/cv designs/cv1-content";
import "../../assets/style/cv-template.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../sub component/show design data/loading";
import { user_action } from "../../redux/action/cv-action";
import Pricing from "../../sub component/home/pricing";
import { user_payment } from "../../redux/action/auth-action";
import Verify_email from "../verify-email/verify-email";

const Cv1 = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      await dispatch(user_payment());

      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const is_Active = useSelector((state) => state.auth.is_Active);
  const isUser_verified = useSelector(
    (state) => state.cv.user.email_verified_at
  );

  useEffect(() => {
    // Create a link element for Bootstrap CSS
    const bootstrapCSS = document.createElement("link");
    bootstrapCSS.href =
      "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
    bootstrapCSS.rel = "stylesheet";
    bootstrapCSS.integrity =
      "sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC";
    bootstrapCSS.crossOrigin = "anonymous";

    // Create a script element for Bootstrap JS
    const bootstrapJS = document.createElement("script");
    bootstrapJS.src =
      "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js";
    bootstrapJS.integrity =
      "sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM";
    bootstrapJS.crossOrigin = "anonymous";

    // Append the link and script to the document head
    document.head.appendChild(bootstrapCSS);
    document.body.appendChild(bootstrapJS);

    // Cleanup function to remove the link and script when the component unmounts
    return () => {
      document.head.removeChild(bootstrapCSS);
      document.body.removeChild(bootstrapJS);
    };
  }, []);

  return (
    <>
     <Helmet>
  <title>
    Create CV - Professional Templates for All Needs - Job Version
  </title>
  <meta
    name="description"
    content="Create your perfect CV with our professional templates. Choose from a variety of designs tailored for different industries and languages, and build your CV for free. Job Version offers user-friendly and customizable CV templates to help you stand out in your job search."
  />
  <meta
    name="keywords"
    content="create CV, CV templates, professional CV, resume templates, free CV builder, customizable CV, CV designs, multilingual CV"
  />
  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  {/* Open Graph Tags */}
  <meta
    property="og:title"
    content="Create CV - Professional Templates for All Needs - Job Version"
  />
  <meta
    property="og:description"
    content="Create your perfect CV with our professional templates. Choose from a variety of designs tailored for different industries and languages, and build your CV for free. Job Version offers user-friendly and customizable CV templates to help you stand out in your job search."
  />
  <meta
    property="og:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta property="og:url" content="https://jobversion.com/resumes=1" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Job Version" />

  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Create CV - Professional Templates for All Needs - Job Version"
  />
  <meta
    name="twitter:description"
    content="Create your perfect CV with our professional templates. Choose from a variety of designs tailored for different industries and languages, and build your CV for free. Job Version offers user-friendly and customizable CV templates to help you stand out in your job search."
  />
  <meta
    name="twitter:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta name="twitter:url" content="https://jobversion.com/resumes=1" />

  {/* Canonical Tag */}
  <link rel="canonical" href="https://jobversion.com/resumes=1" />

  {/* Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "Create CV - Professional Templates for All Needs",
        "description": "Create your perfect CV with our professional templates. Choose from a variety of designs tailored for different industries and languages, and build your CV for free. Job Version offers user-friendly and customizable CV templates to help you stand out in your job search.",
        "image": "https://jobversion.com/images/Brand-photos.png",
        "url": "https://jobversion.com/resumes=1",
        "publisher": {
          "@type": "Organization",
          "name": "Job Version",
          "logo": {
            "@type": "ImageObject",
            "url": "https://jobversion.com/images/Brand-photos.png"
          }
        }
      }
    `}
  </script>
</Helmet>

      {loading ? (
        <Loading />
      ) : isUser_verified === null ? (
        <Verify_email />
      ) : (
        <div className="flex cv1-container lg:h-screen  pt-[80px]">
          <Design className=" w-1/2 " />
          <Cv1_content className=" w-1/2" />
        </div>
      )}
    </>
  );
};

export default Cv1;
