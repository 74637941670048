import React, { useCallback, useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import "rsuite/Accordion/styles/index.css";
import "rsuite/Input/styles/index.css";

import LocationOnIcon from "@mui/icons-material/LocationOnOutlined"; // (Optional) Import component styles. If you are using Less, import the `index.less` file.
import "rsuite/Input/styles/index.css";
import "rsuite/InputGroup/styles/index.css";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import Cookies from "js-cookie";
import brand_image from "../../../../assets/image/Brand-photos.png";

import { Country, State, City } from "country-state-city";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import {
  city_search_value,
  country_search_value,
  get_high,
  get_middle,
  getSearch_high,
  language_search_value,
  lecturers_get,
  name_search_value,
  search_high,
  search_middle,
  state_search_value,
  study_mode_search_value,
  subject_search_value,
} from "../../../../redux/action/lecturers-action";
import HomeFooter from "../../../../sub component/home/footer";
import Search_loading from "../../search loading/search-loading";
import Loading from "../../../../sub component/show design data/loading";
import Login from "../../../../auth/login";
import High_Pagination from "./high-page";
import { user_action } from "../../../../redux/action/cv-action";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@rsuite/icons/Search";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import TuneIcon from "@mui/icons-material/Tune";
import { user_get } from "../../../../redux/action/auth-action";
import { Box, Rating } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const High_data = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [Subject, setSubject] = useState("");
  const [Study_mode, setStudy_mode] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [nameSearch_data, setNameSearch_data] = useState([]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  const [countries, setCountries] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const handleCheckboxChange = (value) => {
    if (selectedSubject === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedSubject("");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedSubject(value);
    }
  };
  const subjects = [
    { value: "Kurdish", label: "Kurdish" },
    { value: "Arabic", label: "Arabic" },
    { value: "English", label: "English" },
    { value: "math", label: "math" },
    { value: "science", label: "science" },
    { value: "chemistry", label: "chemistry" },
    { value: "Physics", label: "Physics" },
    { value: "Biology", label: "Biology" },
    { value: "computer", label: "computer" },
    { value: "economy", label: "economy" },
  ];

  const languages = [
    { value: "Kurdish", label: "Kurdish" },
    { value: "Arabic", label: "Arabic" },
    { value: "English", label: "English" },
  ];

  const modes = [
    { value: "offline", label: "offline" },
    { value: "online", label: "online" },
  ];

  useEffect(() => {
    // Fetch all countries
    const countryList = Country.getAllCountries().map((country) => ({
      name: country.name,
      isoCode: country.isoCode,
    }));
    setCountries(countryList);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states of the selected country
      const stateList = State.getStatesOfCountry(selectedCountry.isoCode).map(
        (state) => ({
          name: state.name,
          isoCode: state.isoCode,
        })
      );
      setStates(stateList);
      setSelectedState(null);
      setCities([]);
    } else {
      setStates([]);
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities of the selected state
      const cityList = City.getCitiesOfState(
        selectedCountry.isoCode,
        selectedState.isoCode
      ).map((city) => ({
        name: city.name,
      }));
      setCities(cityList);
    } else {
      setCities([]);
    }
  }, [selectedState, selectedCountry]);

  // const handleCountryChange = (e) => {
  //   const country = countries.find((c) => c.isoCode === e.target.value);
  //   setSelectedCountry(country || null);
  //   setCountry(country?.name || "");
  //   setSelectedState(null);
  //   setSelectedCity("");
  // };
  // const handleStateChange = (e) => {
  //   const state = states.find((s) => s.isoCode === e.target.value);
  //   setSelectedState(state || null);
  //   setState(state?.name || "");
  //   setSelectedCity("");
  // };

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const high_search_get =
    useSelector((state) => state.lecturers.high_search_get) || "";
  const total_high = useSelector((state) => state.lecturers.total_high);

  const Data = useSelector((state) => state.lecturers.high_get) || "";
  const search = useSelector((state) => state.lecturers.search) || "";
  const page = useSelector((state) => state.lecturers.page);
  const second_job_page = useSelector((state) => state.job.second_job_page);
  useEffect(() => {
    setData(Data);
  }, [Data]);
  useEffect(() => {
    setSearchData(high_search_get);
  }, [high_search_get]);
  useEffect(() => {
    setData(search);
  }, [search]);
  useEffect(() => {
    setData(page);
  }, [page]);
  useEffect(() => {
    setData(second_job_page);
  }, [second_job_page]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(get_high());
      await dispatch(getSearch_high());
      await dispatch(user_action());
      await dispatch(lecturers_get());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    const Search = async () => {
      setSearchLoading(true);
      await dispatch(
        search_high(
          country,
          state,
          selectedCity,
          selectedSubject,
          selectedMode,
          selectedLanguage,
          nameSearch_data
        )
      );
      dispatch(country_search_value(country));
      dispatch(city_search_value(selectedCity));
      dispatch(state_search_value(state));
      dispatch(subject_search_value(selectedSubject));
      dispatch(study_mode_search_value(selectedMode));
      dispatch(language_search_value(selectedLanguage));
      setSearchLoading(false);
    };

    //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
    if (
      country !== "" ||
      state !== "" ||
      selectedCity !== "" ||
      selectedSubject !== "" ||
      selectedMode !== "" ||
      selectedLanguage !== ""
    ) {
      Search();
    }
  }, [
    country || country == " ",
    state,
    selectedCity,
    selectedSubject,
    selectedMode,
    selectedLanguage,
    dispatch,
  ]);

  const searchPerformed = useSelector(
    (state) => state.services.searchPerformed
  );
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };
  const token = useSelector((state) => state.cv.isAuthuntucated);
  const navigate = useNavigate();

  const user_id = useSelector((state) => state.cv.user.id);
  const services_data = useSelector((state) => state.lecturers.lecturer_get);
  const [lecturerData, setLecturerData] = useState([]);
  const [actionsCompleted, setActionsCompleted] = useState(false);

  useEffect(() => {
    setLecturerData(services_data);
  }, [services_data]);

  const user = lecturerData.find((user) => user.user_id === `${user_id}`);

  // bo mawaya agar dubara habu neshan nadre
  const shownSubject = new Set();
  const shownPhoneSubject = new Set();

  const showncountry = new Set();
  const shownPhonecountry = new Set();

  const shownState = new Set();
  const shownPhoneState = new Set();

  const shownCity = new Set();
  const shownPhoneCity = new Set();

  const shownStudy_mode = new Set();
  const shownPhoneStudy_mode = new Set();

  const shownLanguage = new Set();
  const shownPhoneLanguage = new Set();

  const subjectCount = searchData.reduce((acc, item) => {
    acc[item.Subject] = (acc[item.Subject] || 0) + 1;
    return acc;
  }, {});

  const countryCount = searchData.reduce((acc, item) => {
    acc[item.country] = (acc[item.country] || 0) + 1;
    return acc;
  }, {});

  const study_modeCount = searchData.reduce((acc, item) => {
    acc[item.study_mode] = (acc[item.study_mode] || 0) + 1;
    return acc;
  }, {});

  const languageCount = searchData.reduce((acc, item) => {
    acc[item.language] = (acc[item.language] || 0) + 1;
    return acc;
  }, {});

  const StateCount = searchData.reduce((acc, item) => {
    acc[item.state] = (acc[item.state] || 0) + 1;
    return acc;
  }, {});
  const CityCount = searchData.reduce((acc, item) => {
    acc[item.city] = (acc[item.city] || 0) + 1;
    return acc;
  }, {});

  const handleSubjectChange = (value) => {
    if (selectedSubject === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedSubject(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedSubject(value);
    }
  };

  const handleLanguageChange = (value) => {
    if (selectedLanguage === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedLanguage(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedLanguage(value);
    }
  };

  const handleModeChange = (value) => {
    if (selectedMode === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedMode(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedMode(value);
    }
  };
  const handleCountryChange = (value) => {
    if (country === value) {
      // If the same checkbox is clicked again, uncheck it
      setCountry(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setCountry(value);
    }
  };
  const handleStateChange = (value) => {
    if (state === value) {
      // If the same checkbox is clicked again, uncheck it
      setState(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setState(value);
    }
  };

  const handleCityChange = (value) => {
    if (selectedCity === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedCity(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedCity(value);
    }
  };
  const nameSearch = async () => {
    setSearchLoading(true);
    await dispatch(
      search_high(
        country,
        state,
        selectedCity,
        selectedSubject,
        selectedMode,
        selectedLanguage,
        nameSearch_data
      )
    );
    setSearchLoading(false);
    dispatch(name_search_value(nameSearch_data));
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [showFilter, setShowFilter] = useState(false);

  // Function to toggle filter options visibility
  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  //henany barwar
  const isExpired = (subscriptionDate) => {
    const now = new Date();
    return new Date(subscriptionDate) < now;
  };

  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>High School Teachers - Job Version</title>
        <meta
          name="description"
          content="Explore profiles of High School teachers on Job Version. Find experienced and qualified teachers to guide students through their crucial high school years."
        />
        <meta
          name="keywords"
          content="High School Teachers, High School Educators, Job Version, Education, High School Education, Qualified Teachers"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="High School Teachers - Job Version"
        />
        <meta
          property="og:description"
          content="Discover the profiles of High School teachers on Job Version. Connect with experienced educators to support students' academic growth."
        />
        <meta
          property="og:image"
          content="https://jobversion.com/images/Brand-photos.png"
        />
        <meta
          property="og:url"
          content="https://jobversion.com/teachers/High-School-Teachers"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Job Version" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="High School Teachers - Job Version"
        />
        <meta
          name="twitter:description"
          content="Explore profiles of High School teachers on Job Version. Find experienced and qualified teachers to guide students through their crucial high school years."
        />
        <meta
          name="twitter:image"
          content="https://jobversion.com/images/Brand-photos.png"
        />
        <meta
          name="twitter:url"
          content="https://jobversion.com/teachers/High-School-Teachers"
        />

        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://jobversion.com/teachers/High-School-Teachers"
        />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "High School Teachers",
        "description": "Discover profiles of High School teachers on Job Version. Find experienced and qualified teachers to guide students through their crucial high school years.",
        "image": "https://jobversion.com/images/Brand-photos.png",
        "url": "https://jobversion.com/teachers/High-School-Teachers",
        "publisher": {
          "@type": "Organization",
          "name": "Job Version",
          "logo": {
            "@type": "ImageObject",
            "url": "https://jobversion.com/images/Brand-photos.png"
          }
        }
      }
    `}
        </script>
      </Helmet>

      <div
        style={{
          direction:
            i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <div>
            {searchLoading ? (
              <Search_loading />
            ) : (
              <div className="flex flex-col justify-between min-h-screen">
                <div className="flex cards-container justify-center min-w-[100%] pt-[150px] w-full">
                  <div className="main-freeLancer-container max-w-[900px] main-freeLancer-details-container min-w-[90%] flex justify-center gap-[40px]">
                    <div className="left-details-container  hidden md:block ">
                      <div className="left-details  border rounded-[10px]   ">
                        <div className="left-details-header  rounded-t-[9px]  text-[20px] bg-[#ffb923] h-[45px]  text-white flex  font-[500]   border-top-[20px] justify-center items-center">
                          {t("High_School_lecturer_list")}{" "}
                        </div>
                        <div
                          className=" flex  flex-end flex-col"
                          style={{
                            direction:
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "rtl"
                                : "ltr",
                          }}
                        >
                          <form
                            onSubmit={nameSearch}
                            className="flex items-center min-w-[90%] mt-[20px] mb-[10px] mx-auto"
                          >
                            <label className="sr-only">Search</label>
                            <div className="relative w-full">
                              <input
                                type="text"
                                id="simple-search"
                                className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={t("name")}
                                required
                                value={nameSearch_data}
                                onChange={(e) => {
                                  setNameSearch_data(e.target.value);
                                }}
                              />
                            </div>

                            <button
                              type="submit"
                              className="p-2.5 ms-2 text-sm font-medium text-white  bg-[#ffb923] rounded-lg   hover:bg-[#ffc64a]   rounded-lg  dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                              <span className="sr-only">Search</span>
                            </button>
                          </form>
                          <div className="mx-[10px] mb-[5px]">
                            <h1 className="mx-[10px]  flex gap-2.5 items-center  mt-[15px] mb-[7px]">
                              <button
                                type="submit"
                                className="p-1 ms-2 text-sm font-medium text-white  bg-[#ffb923] rounded-lg   hover:bg-[#ffc64a]   rounded-lg  dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                <TuneIcon className=" text-white" />

                                <span className="sr-only">Search</span>
                              </button>

                              {t("Filtering_by")}
                            </h1>
                            <Accordion
                              expanded={expanded === "panel2"}
                              onChange={handleChange("panel2")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("Subject")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownSubject.has(data.Subject)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownSubject.add(data.Subject); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            selectedSubject === data.Subject
                                          }
                                          onChange={() =>
                                            handleSubjectChange(data.Subject)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          {data.Subject == "economy"
                                            ? t("economy")
                                            : data.Subject == "Kurdish"
                                            ? t("Kurdish")
                                            : data.Subject == "Arabic"
                                            ? t("Arabic")
                                            : data.Subject == "English"
                                            ? t("English")
                                            : data.Subject == "math"
                                            ? t("math")
                                            : data.Subject == "science"
                                            ? t("science")
                                            : data.Subject == "chemistry"
                                            ? t("chemistry")
                                            : data.Subject == "computer"
                                            ? t("computer ")
                                            : data.Subject == "Biology"
                                            ? t("Biology")
                                            : data.Subject == "Physics"
                                            ? t("Physics")
                                            : null}

                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {subjectCount[data.Subject]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>{" "}
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel11"}
                              onChange={handleChange("panel11")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("Country")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (showncountry.has(data.country)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    showncountry.add(data.country); // Add company name to set

                                    return (
                                      <div
                                        key={`country-${data.id}`}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={country === data.country}
                                          onChange={() =>
                                            handleCountryChange(data.country)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          {data.country}

                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {countryCount[data.country]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel3"}
                              onChange={handleChange("panel3")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("State")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownState.has(data.state)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownState.add(data.state); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={state === data.state}
                                          onChange={() =>
                                            handleStateChange(data.state)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          {data.state}

                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {StateCount[data.state]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel4"}
                              onChange={handleChange("panel4")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("City")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownCity.has(data.city)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownCity.add(data.city); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedCity === data.city}
                                          onChange={() =>
                                            handleCityChange(data.city)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          {data.city}

                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {CityCount[data.city]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel5"}
                              onChange={handleChange("panel5")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("language")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownLanguage.has(data.language)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownLanguage.add(data.language); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            selectedLanguage === data.language
                                          }
                                          onChange={() =>
                                            handleLanguageChange(data.language)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          {data.language == "English"
                                            ? t("English")
                                            : data.language == "Kurdish"
                                            ? t("Kurdish")
                                            : data.language == "Arabic"
                                            ? t("Arabic")
                                            : null}

                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {languageCount[data.language]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel6"}
                              onChange={handleChange("panel6")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("study_mode")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownStudy_mode.has(data.study_mode)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownStudy_mode.add(data.study_mode); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            selectedMode === data.study_mode
                                          }
                                          onChange={() =>
                                            handleModeChange(data.study_mode)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          {data.study_mode}

                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {study_modeCount[data.study_mode]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* phone searchbar */}
                    <div className="left-details-container mt-[-5px]  block md:hidden ">
                      <form
                        onSubmit={nameSearch}
                        className="flex items-center min-w-full mx-auto"
                      >
                        <label className="sr-only">Search</label>
                        <div className="relative w-full">
                          <div className="flex  items-end">
                            <button
                              type="submit"
                              className="max-w-[40px]  justify-center   absolute inset-y-0  start-0 flex items-center "
                              // style={{
                              //   marginRight:
                              //     i18n.language === "ku" || i18n.language === "ar"
                              //       ? "0px"
                              //       : "0px",
                              //   marginLeft:
                              //     i18n.language === "ku" || i18n.language === "ar"
                              //       ? "0px"
                              //       : "10px",
                              // }}
                            >
                              <SearchIcon className="mx-2" />
                              <div className="bg-gray-300 h-[20px]  w-[1px]"></div>
                            </button>
                          </div>
                          <input
                            type="text"
                            id="simple-search"
                            className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={t("name")}
                            required
                            value={nameSearch_data}
                            onChange={(e) => {
                              setNameSearch_data(e.target.value);
                            }}
                          />
                        </div>

                        <button
                          type="button"
                          onClick={toggleFilter} // Toggle visibility on click
                          className="p-[8px] ms-2 text-sm font-medium text-white bg-[#ffb923] rounded-lg hover:bg-[#ffc64a] dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          <TuneIcon
                            className=" text-white "
                            style={{ fontSize: "26px" }}
                          />
                          <span className="sr-only">Search</span>
                        </button>
                      </form>

                      {showFilter && ( // Conditional rendering of the div
                        <div className="filter-options mt-4 p-2 border rounded-lg  dark:bg-gray-800">
                          <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{t("Subject")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneSubject.has(data.Subject)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneSubject.add(data.Subject); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          selectedSubject === data.Subject
                                        }
                                        onChange={() =>
                                          handleSubjectChange(data.Subject)
                                        }
                                      />
                                      <div className="flex gap-3  main-freeLancer-container-text justify-between w-full items-center">
                                        {data.Subject == "economy"
                                          ? t("economy")
                                          : data.Subject == "Kurdish"
                                          ? t("Kurdish")
                                          : data.Subject == "Arabic"
                                          ? t("Arabic")
                                          : data.Subject == "English"
                                          ? t("English")
                                          : data.Subject == "math"
                                          ? t("math")
                                          : data.Subject == "science"
                                          ? t("science")
                                          : data.Subject == "chemistry"
                                          ? t("chemistry")
                                          : data.Subject == "computer"
                                          ? t("computer")
                                          : data.Subject == "Biology"
                                          ? t("Biology")
                                          : data.Subject == "Physics"
                                          ? t("Physics")
                                          : null}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {subjectCount[data.Subject]}
                                          </p>
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>{" "}
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel22"}
                            onChange={handleChange("panel22")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{t("Country")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhonecountry.has(data.country)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhonecountry.add(data.country); // Add company name to set

                                  return (
                                    <div
                                      key={`country-${data.id}`}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={country === data.country}
                                        onChange={() =>
                                          handleCountryChange(data.country)
                                        }
                                      />
                                      <div className="flex gap-3  main-freeLancer-container-text justify-between w-full items-center">
                                        {data.country}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {countryCount[data.country]}
                                          </p>
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{t("State")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneState.has(data.state)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneState.add(data.state); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={state === data.state}
                                        onChange={() =>
                                          handleStateChange(data.state)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.state}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {StateCount[data.state]}
                                          </p>
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel4"}
                            onChange={handleChange("panel4")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{t("City")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneCity.has(data.city)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneCity.add(data.city); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectedCity === data.city}
                                        onChange={() =>
                                          handleCityChange(data.city)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.city}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {CityCount[data.city]}
                                          </p>
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel5"}
                            onChange={handleChange("panel5")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{t("language")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneLanguage.has(data.language)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneLanguage.add(data.language); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          selectedLanguage === data.language
                                        }
                                        onChange={() =>
                                          handleLanguageChange(data.language)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.language == "English"
                                          ? t("English")
                                          : data.language == "Kurdish"
                                          ? t("Kurdish")
                                          : data.language == "Arabic"
                                          ? t("Arabic")
                                          : null}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {languageCount[data.language]}
                                          </p>
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel6"}
                            onChange={handleChange("panel6")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{t("study_mode")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (
                                    shownPhoneStudy_mode.has(data.study_mode)
                                  ) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneStudy_mode.add(data.study_mode); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          selectedMode === data.study_mode
                                        }
                                        onChange={() =>
                                          handleModeChange(data.study_mode)
                                        }
                                      />
                                      <div className="flex  main-freeLancer-container-text gap-3 justify-between w-full items-center">
                                        {data.study_mode}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {study_modeCount[data.study_mode]}
                                          </p>
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}
                    </div>
                    <div className="main-card-container flex flex-col w-[900px] gap-[50px]">
                      <div className="freeLancers_border max-w-[900px] min-w-[100%] border rounded-[10px] min-h-[100px]">
                        <div className="flex justify-end">
                          <Link
                            to={
                              token
                                ? user
                                  ? "/teachers/update "
                                  : "/teachers/add"
                                : "/login"
                            }
                            className="left-search-button flex justify-center md:text-[15px]  text-[12px] items-center md:mt-[-50px]   mt-[-40px] md:w-[150px] w-[120px] max-w-[110px] md:max-w-[150px] bg-[#ffb923] rounded-[5px] text-white md:h-[40px] h-[30px] hover:bg-[#ffcc5f]"
                          >
                            {user ? t("update_Lecturer") : t("add_lecturer")}
                          </Link>
                        </div>

                        {data.length === 0 && (
                          <div className="search-message flex justify-center text-center items-center h-[100%] main-freeLancer-container-text">
                            {t("not_haveData")}
                          </div>
                        )}
                        <div className="freelancer-card- p-1">
                          {data
                            ? data.map((item, index) => (
                                <Link
                                  target="blank"
                                  key={item.id}
                                  to={
                                    token
                                      ? `/teachers/teacher-detail/${item.id}`
                                      : "/login"
                                  }
                                >
                                  <Card
                                    sx={{
                                      minWidth: "100%",
                                      boxShadow: "none",
                                    }}
                                    className="carsrsrs"
                                  >
                                    <CardHeader
                                      avatar={
                                        <img
                                          src={item.image || brand_image}
                                          className="rounded-full card-avatar p-0.5   border-red-900"
                                          alt="Avatar"
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "20px"
                                                : "0px",
                                          }}
                                        />
                                      }
                                      title={
                                        <h1
                                          className="freelancer-title break-words"
                                          style={{
                                            fontFamily: '"Poppins", sans-serif',
                                          }}
                                        >
                                          {item.name}
                                        </h1>
                                      }
                                      subheader={
                                        <Box
                                          variant="body2"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                            fontStyle: "normal",
                                          }}
                                          className="main-freeLancer-container-text max-w-[90%] break-words"
                                        >
                                          <span>{item.Subject}</span>
                                          <Box
                                            className="mt-1"
                                            sx={{
                                              "& > legend": { mt: 2 },
                                            }}
                                          >
                                            <Rating
                                              name="size-small"
                                              readOnly
                                              value={Number(
                                                item.reviews_avg_rating
                                              )}
                                              size="small"
                                            />
                                          </Box>
                                        </Box>
                                      }
                                    />

                                    <CardContent>
                                      <p className="main-freeLancer-container-text break-words">
                                        {item.description}
                                      </p>
                                    </CardContent>
                                    <CardActions
                                      disableSpacing
                                      className="mb-[-11px]"
                                    >
                                      <IconButton
                                        aria-label="add to favorites"
                                        onClick={() =>
                                          handlePhoneCall(item.phone)
                                        }
                                      >
                                        <LocalPhoneIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="email"
                                        onClick={() =>
                                          handleSendEmail(item.email)
                                        }
                                      >
                                        <EmailIcon />
                                      </IconButton>
                                      <div className="  flex gap-2 ml-[20px]">
                                        <div className="bg-gray-100 main-freeLancer-container-text  flex items-center gap-1  px-[10px] font-[300]  text-gray-700 rounded-[3px]">
                                          <BeenhereOutlinedIcon
                                            style={{ fontSize: "15px" }}
                                          />

                                          {item.study_mode}
                                        </div>
                                        <div className="bg-gray-100 main-freeLancer-container-text  flex items-center gap-1  px-[10px] font-[300]  text-gray-700 rounded-[3px]">
                                          <LanguageIcon
                                            style={{ fontSize: "17px" }}
                                          />
                                          {item.language}
                                        </div>
                                      </div>
                                    </CardActions>
                                    {index !== data.length - 1 && (
                                      <hr className=" mx-[20px] " />
                                    )}
                                  </Card>
                                </Link>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className="freelancer-pagination mb-[80px]  flex justify-center   ">
                        {total_high > 10 ? (
                          <div>
                            <High_Pagination />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-full">
                  <HomeFooter />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default High_data;
