import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { user_action } from "../../redux/action/cv-action";
import Loading from "../../sub component/show design data/loading";
import { useDispatch } from "react-redux";
import HomeFooter from "../../sub component/home/footer";
// Import the team manager's photo
import teamManagerPhoto from "../../assets/image/manager-photo.jpg"; // Make sure the path is correct
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());

      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const { t, i18n } = useTranslation();

  return (
    <>
<Helmet>
  {/* Basic Meta Tags */}
  <title>About Us - Job Version</title>
  <meta
    name="description"
    content="Learn more about Job Version, our mission, and the team behind our innovative career platform. Discover how we are dedicated to helping you advance your career with our comprehensive tools and resources."
  />
  <meta
    name="keywords"
    content="About Job Version, Job Version team, career platform, mission, company information"
  />
  <meta name="robots" content="index, follow" />

  {/* Open Graph Tags */}
  <meta property="og:title" content="About Us - Job Version" />
  <meta
    property="og:description"
    content="Explore the story behind Job Version, meet our team, and understand our mission to revolutionize career development. Learn how we are committed to supporting your career growth."
  />
  <meta
    property="og:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta property="og:url" content="https://jobversion.com/about-us" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Job Version" />

  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="About Us - Job Version" />
  <meta
    name="twitter:description"
    content="Find out more about Job Version, our vision, and the passionate team dedicated to your career success. Learn about our goals and how we aim to help you achieve your professional objectives."
  />
  <meta
    name="twitter:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta name="twitter:url" content="https://jobversion.com/about-us" />

  {/* Canonical Tag */}
  <link rel="canonical" href="https://jobversion.com/about-us" />

  {/* Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Job Version",
        "url": "https://jobversion.com/about-us",
        "logo": "https://jobversion.com/images/Brand-photos.png",
        "description": "Learn about Job Version, our mission, and the team behind our career development platform. We are committed to providing tools and resources to help you advance your career."
      }
    `}
  </script>
</Helmet>


      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
            className="min-h-screen px-1 sm:mx-6 mx-2 md:px-8 sm:mt-[120px]  mt-[90px]"
          >
            <div className="max-w-7xl mx-auto">
              <div className="bg-white rounded-lg sm:p-8 p-2">
                <section className="mb-8">
                  
                {/* Team Manager Section */}
                <section className="mb-8 ">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    {t("manager")}
                  </h2>
                  <div className="max-w-[400px]">
                  <img
                    src={teamManagerPhoto}
                    alt="Team Manager"
                    className=" pdf-page  border shadow-md  rounded-[20px]     mb-4"
                  />
                  </div>


              
                  <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Mohammed Hamza
                  </h2>
                  <h2 className="text-lg font-[500] text-gray-800 mb-4">
                  {t("manager_role")}
</h2>
                  <p className="text-gray-700">{t("manager_description")}</p>
                </section>

                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    {t("Our_Mission")}
                  </h2>
                  <p className="text-gray-700">{t("Our_Mission_content")}</p>
                </section>

                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    {t("What_We_Offer")}
                  </h2>
                  <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li>
                      <strong>{t("Create_CV")} : </strong>{" "}
                      {t("ceate_cv_content")}
                    </li>
                    <li>
                      <strong>{t("Jobs")} : </strong> {t("jobs_content")}
                    </li>
                    <li>
                      <strong>{t("Freelancers")} : </strong>{" "}
                      {t("freelance_content")}
                    </li>
                    <li>
                      <strong>{t("Services")} : </strong>{" "}
                      {t("services_content")}{" "}
                    </li>
                    <li>
                      <strong>{t("lecturers")} : </strong>{" "}
                      {t("lecturers_content")}
                    </li>
                  </ul>
                </section>

                <section className="mb-8">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    {t("Our_Values")}
                  </h2>
                  <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li>
                      <strong>{t("Innovation")}:</strong> {t("Innovation_content")}
                    </li>
                    <li>
                      <strong>{t("Customer_Centricity")}:</strong>{" "}
                      {t("Customer_Centricity_content")}
                    </li>
                  </ul>
                </section>


                <section>
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    {t("Contact_us")}
                  </h2>
                  <p className="text-gray-700">
                    {t("contact_content")} , <span dir="ltr">+964 772 725 23 23</span>
                  </p>
                </section>
              </div>
            </div>
          </div>
          <HomeFooter />
        </>
      )}
    </>
  );
};

export default AboutUs;
