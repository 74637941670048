import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import brand from "../../assets/image/Brand.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTranslation } from "react-i18next";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import "../../assets/style/footer.css";
const HomeFooter = () => {

  const { i18n, t } = useTranslation();
  const languages = [
    { code: "en", name: "English" },
    { code: "ku", name: "كوردی" },
    { code: "ar", name: "العربية" },
  ];
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      changeLanguage(storedLanguage);
    }
  }, []);
  const handleClick = () => {
    window.open(`tel:+9647517252321`);
  };
  useEffect(() => {
    // Scroll to top when component is mounted
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <footer dir="ltr" className="w-full bg-gray-50 mt-[70px]">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-8 py-10 max-w-xs mx-auto sm:max-w-2xl lg:max-w-full">
            <div className="col-span-full lg:mb-10 max-lg:border-b max-lg:pb-8 lg:border-r border-gray-200 lg:col-span-2 lg:max-w-xs lg:mb-0">
              <Link
                to={"/"}
                className="flex justify-center mb-12 lg:justify-start"
              >
                <img src={brand} alt="brand" title="karbian brand" className="h-[60px]" />
              </Link>
              <ul className="grid gap-5">
                <li className="flex items-center justify-center gap-2 lg:justify-start">
                  <EmailOutlinedIcon style={{ color: "#ffb923" }} />
                  info@jobversion.com
                                  </li>
                <li className="flex items-center justify-center gap-2 lg:justify-start">
                  <LocalPhoneOutlinedIcon
                    style={{ color: "#ffb923" }}
                    vghk
                    ku
                  />
                  +964 751 725 23 21  <br /> +964 772 725 23 23
                </li>
                <li className="flex items-center justify-center gap-2 lg:justify-start">
                  <FmdGoodOutlinedIcon style={{ color: "#ffb923" }} />
                  kirkuk , iraq
                </li>
              </ul>
            </div>
            <div className="lg:mx-auto text-left ">
              <h4 className="text-lg text-gray-900 font-medium mb-7">
              {t("Resources")}

              </h4>
              <ul className="text-sm  transition-all duration-500">
                <li className="mb-6">
                  <Link to={"/"} className="text-gray-600 hover:text-gray-900">
                    {t("Home")}
                  </Link>
                </li>
                <li className="mb-6">
                  <Link
                    to={"/jobs"}
                    className=" text-gray-600 hover:text-gray-900"
                  >
                    {" "}
                    {t("Jobs")}
                  </Link>
                </li>
                <li className="mb-6">
                  <Link
                    to={"/freelance"}
                    className=" text-gray-600 hover:text-gray-900"
                  >
                    {t("Freelancers")}
                  </Link>
                </li>
                <li className="mb-6">
                  <Link
                    to={"/lecturers"}
                    className=" text-gray-600 hover:text-gray-900"
                  >
                    {t("lecturers")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/services"}
                    className=" text-gray-600 hover:text-gray-900"
                  >
                    {t("Services")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="lg:mx-auto text-left">
              <h4 className="text-lg text-gray-900 font-medium mb-7">
                {t("Support")}
              </h4>
              <ul className="text-sm  transition-all duration-500">
                <li className="mb-6">
                  <Link
                    to={"/Contact-us"}
                    className=" text-gray-600 hover:text-gray-900"
                  >
                    {t("Contact_us")}
                  </Link>
                </li>
                <li>
                  <Link to={'/About-us'}
                    href="javascript:;"
                    className=" text-gray-600 hover:text-gray-900"
                  >
                    {t("About_us")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="lg:mx-auto text-left ">
              <h4 className="text-lg text-gray-900 font-medium mb-7">
                {t("social_media")}
              </h4>
              <ul className="text-sm  transition-all duration-500">
                <li className="mb-6">
                  <a target="blank" href="https://www.instagram.com/jobversion?igsh=cG9qbzJ5N2FkeTJo&utm_source=qr"
                  >
                    Instagram
                  </a>
                 

                </li>


                <li className="mb-6">
                  <a target="blank" href="https://t.me/muhammadhammzaa"
                  >
                    Telegram
                  </a>
                 
                 
                </li>
                
                <li className="mb-6">
                <a href="https://api.whatsapp.com/send/?phone=9647517252321&text&type=phone_number&app_absent=0" 
                onClick={handleClick}  
                
                 
                
                className=" text-gray-600 hover:text-gray-900 cursor-pointer"
                  >
                    Whats app
                  </a>
                 
                </li>
              </ul>
            </div>
            <div className="lg:mx-auto text-left ">
              <h4 className="text-lg text-gray-900 font-medium mb-7">
                {t("language")}
              </h4>
              <ul className="text-sm  transition-all duration-500">
                {languages.map((lang) => {
                  return (
                    <>
                      <p className="cursor-pointer"
                        onClick={() => {
                          changeLanguage(lang.code);
                        }}
                      >
                        <li className="mb-6">{lang.name} </li>
                      </p>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="py-7 border-t border-gray-200">
            <div className="flex items-center justify-center ">
            <span className="text-sm text-gray-500">
  © {new Date().getFullYear()} . {t('All_rights_reserved')} .  {" "}
  {t("Developed_by")}
  {" "}
  <a href="https://www.instagram.com/mohammedhammzaa?igsh=MWgxaHg4eXJyYnV2NQ%3D%3D&utm_source=qr" className="text-[#ffb923] font-bold  hover:text-[#ffce63]  ">
{
   i18n.language === "ku" || i18n.language === "ar" ? "محمد حمزة" : " Mohammed hamza"

}
  </a>

</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomeFooter;
