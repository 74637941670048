import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import "rsuite/Accordion/styles/index.css";
import "rsuite/Input/styles/index.css";
import WorkHistoryIcon from "@mui/icons-material/WorkHistoryOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import "rsuite/Input/styles/index.css";
import "rsuite/InputGroup/styles/index.css";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/style/main-pages.css";
import Loading from "../../../sub component/show design data/loading";
import "../../../assets/style/search_loading.css";
import HomeFooter from "../../../sub component/home/footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { job_details } from "../../../redux/action/job-action";
import Error404 from "../../error page/error404";
import { user_action } from "../../../redux/action/cv-action";
import Cookies from "js-cookie";
import brand_image from "../../../assets/image/Brand-photos.png";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import "../../../assets/style/details.css";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import PartyModeOutlinedIcon from "@mui/icons-material/PartyModeOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import {
  services_details,
  services_get,
  services_update_details,
} from "../../../redux/action/services-action";
import {
  lecturers_details,
  lecturers_get,
  lecturers_update_details,
  review_get,
} from "../../../redux/action/lecturers-action";
import { Helmet } from "react-helmet";
import Verify_email from "../../verify-email/verify-email";
import { user_payment } from "../../../redux/action/auth-action";
import Pricing from "../../../sub component/home/pricing";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Rating } from "@mui/material";
import { SignalCellularNull } from "@mui/icons-material";
const Lecturers_Profile = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [lecturerData, setLecturerData] = useState([]);

  const data = useSelector((state) => state.lecturers.lecturer_update_details);
  const [loading, setLoading] = useState(true);
  const [actionsCompleted, setActionsCompleted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(user_action());
        await dispatch(lecturers_get());
        await dispatch(lecturers_update_details());
        await dispatch(user_payment());
        await dispatch(review_get(data.id));
        setActionsCompleted(true); // Set actionsCompleted to true when all actions are done
      } catch (error) {
        console.error("An error occurred while dispatching actions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);
  useEffect(() => {
    if (data && data.id) {
      dispatch(review_get(data.id));
    }
  }, [dispatch, data]); // Add `data` as a dependency
  const services_data = useSelector((state) => state.lecturers.lecturer_get);
  const user_id = useSelector((state) => state.cv.user.id);

  useEffect(() => {
    setLecturerData(services_data);
  }, [services_data]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rating, setRating] = useState(); // State for the rating value
  const [review_data, setReview_data] = useState();

  const [comment, setComment] = useState("");
  const reviews = useSelector((state) => state.lecturers.review_get_data);
  const user_reviews = useSelector((state) => state.lecturers.user_review);

  //get authuntucated user review

  useEffect(() => {
    setReview_data(reviews);
  }, [reviews]);

  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };
  useEffect(() => {
    if (actionsCompleted && lecturerData && user_id) {
      const user = lecturerData.find((user) => user.user_id === `${user_id}`);
      if (user) {
        navigate("/teachers/Profile"); // Navigate to "/lecturers/update" route if user found
      } else {
        navigate("/teachers/add"); // Navigate to "/lecturers/add" route if user not found
      }
      setLoading(false);
    }
  }, [actionsCompleted, lecturerData, user_id, navigate]);
  const token = useSelector((state) => state.cv.isAuthuntucated);
  const isUser_verified = useSelector(
    (state) => state.cv.user.email_verified_at
  );
  if (!loading) {
    if (!token) {
      navigate("/login"); // Navigate to "/update" route if user found
    }
  }
  // bo away agar user active naby njeshan nadre
  const is_Active = useSelector((state) => state.auth.is_Active);
  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>
          {data
            ? `${data.Subject||" "}  Teacher Profile - Job Version`
            : "Teacher Profile - Job Version"}
        </title>
        <meta
          name="description"
          content={
            data
              ? data.description
              : "Explore detailed profiles of our teachers on Job Version. Find information about their qualifications, teaching experience, and more."
          }
        />
        <meta
          name="keywords"
          content="Teacher Profile, Teacher Details, Teaching Experience, Qualifications, Job Version, Educational Professionals"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content={
            data ? `${data.Subject} - Teacher Profile` : "Teacher Profile"
          }
        />
        <meta
          property="og:description"
          content={
            data
              ? data.description
              : "View detailed profiles of our teachers including their qualifications and teaching experience on Job Version."
          }
        />
        <meta
          property="og:image"
          content="https://jobversion.com/images/Brand-photos.png"
        />
        <meta
          property="og:url"
          content={`https://jobversion.com/teachers/Profile`}
        />
        <meta property="og:type" content="profile" />
        <meta property="og:site_name" content="Job Version" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={
            data ? `${data.Subject} - Teacher Profile` : "Teacher Profile"
          }
        />
        <meta
          name="twitter:description"
          content={
            data
              ? data.description
              : "Explore teacher profiles including their teaching experience and qualifications on Job Version."
          }
        />
        <meta
          name="twitter:image"
          content="https://jobversion.com/images/Brand-photos.png"
        />
        <meta
          name="twitter:url"
          content={`https://jobversion.com/teachers/Profile`}
        />

        {/* Canonical Tag */}
        <link
          rel="canonical"
          href={`https://jobversion.com/teachers/Profile`}
        />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": "${data ? data.Subject : "Teacher Profile"}",
        "description": "${
          data
            ? data.description
            : "Explore detailed profiles of our teachers including their qualifications and teaching experience on Job Version."
        }",
        "image": "https://jobversion.com/images/Brand-photos.png",
        "url": "https://jobversion.com/teachers/Profile",
        "jobTitle": "Teacher",
        "affiliation": {
          "@type": "EducationalOrganization",
          "name": "Job Version"
        }
      }
    `}
        </script>
      </Helmet>

      <div>
        {loading ? (
          <Loading />
        ) : token ? (
          isUser_verified === null ? (
            <Verify_email />
          ) : is_Active ? (
            <div>
<div className="flex justify-between flex-col min-h-screen"
style={{
                  direction:
                    i18n.language === "ku" || i18n.language === "ar"
                      ? "rtl"
                      : "ltr",
                }}
              >
                <section className="main-freeLancer-container relative pt-10 md:pt-40 mt-8 md:mt-[70px] pb-12 md:pb-24">
                  <div
                    alt="cover-image"
                    className="freelance-details-cover w-full absolute top-0 left-0 z-0 h-40 md:h-60"
                  />
                  <div className="w-full max-w-7xl mx-auto px-4 md:px-6 lg:px-8">
                    <div className="flex items-center justify-center sm:justify-start relative z-10 mb-5">
                      <img
                        src={data.image || brand_image}
                        alt="user-avatar-image"
                        className="border-4 border-solid border-white w-32 h-32 sm:w-40 sm:h-40 md:w-[200px] md:mt-0  mt-[60px] md:h-[200px] rounded-full"
                      />
                      <div
                        className={
                          i18n.language === "ku" || i18n.language === "ar"
                            ? " sm:mb-[100px]  mb-[140px]   absolute sm:left-4 left-[-10px] cursor-pointer"
                            : " sm:mb-[100px]  mb-[140px]   absolute sm:right-4 right-[-10px] cursor-pointer"
                        }
                      >
                        <Link
                          to={"/teachers/update"}
                          className="cursor-pointer main-freeLancer-container-text flex items-center text-white hover:text-white  z-10 "
                        >
                          <div
                            className={
                              i18n.language === "ku" || i18n.language === "ar"
                                ? null
                                : "hidden"
                            }
                          >
                            <svg
                              class="mt-0.5 ml-2 -mr-1 stroke-white stroke-2"
                              fill="none"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              aria-hidden="true"
                            >
                              <path
                                class="transition opacity-0 group-hover:opacity-100"
                                d="M0 5h7"
                              ></path>
                              <path
                                class="transition group-hover:translate-x-[3px]"
                                d="M1 1l4 4-4 4"
                              ></path>
                            </svg>
                          </div>

                          {t("Update_your_profile")}

                          <div
                            className={
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "hidden"
                                : null
                            }
                          >
                            <svg
                              class="mt-0.5 ml-2 -mr-1 stroke-white stroke-2"
                              fill="none"
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              aria-hidden="true"
                            >
                              <path
                                class="transition opacity-0 group-hover:opacity-100"
                                d="M0 5h7"
                              ></path>
                              <path
                                class="transition group-hover:translate-x-[3px]"
                                d="M1 1l4 4-4 4"
                              ></path>
                            </svg>
                          </div>
                        </Link>
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row sm:gap-5 items-center justify-between mb-5">
                      <div className="text-center sm:text-start ">
                        <h3 className="font-manrope font-bold text-xl sm:text-3xl md:text-4xl text-gray-900 mb-1">
                          {data.name}
                        </h3>
                        <p className="font-normal text-sm sm:text-base leading-6 text-gray-500">
                          <LocationOnIcon
                            style={{
                              color: "gray",
                              fontSize: "18px",
                            }}
                          />{" "}
                          {data.city}, {data.state}, {data.country}
                        </p>
                      </div>
                      <div className="flex flex-col gap-[10px] items-center md:mt-0  mt-[30px]">
                        <Box
                          sx={{
                            "& > legend": { mt: 2 },
                          }}
                        >
                          <Rating
                            name="size-small"
                            readOnly
                            value={Number(data.average_rating)}
                            size="medium"
                          />
                        </Box>
                        <button className="rounded-full py-2 px-4 sm:py-3 sm:px-5 bg-gray-100 flex items-center group transition-all duration-500 ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              className="stroke-gray-700 transition-all duration-500"
                              d="M14.1667 11.6666V13.3333C14.1667 14.9046 14.1667 15.6903 13.6785 16.1785C13.1904 16.6666 12.4047 16.6666 10.8333 16.6666H7.50001C5.92866 16.6666 5.14299 16.6666 4.65483 16.1785C4.16668 15.6903 4.16668 14.9047 4.16668 13.3333V11.6666M16.6667 9.16663V13.3333M11.0157 10.434L12.5064 9.44014C14.388 8.18578 15.3287 7.55861 15.3287 6.66663C15.3287 5.77466 14.388 5.14749 12.5064 3.89313L11.0157 2.8993C10.1194 2.3018 9.67131 2.00305 9.16668 2.00305C8.66205 2.00305 8.21393 2.3018 7.31768 2.8993L5.82693 3.89313C3.9454 5.14749 3.00464 5.77466 3.00464 6.66663C3.00464 7.55861 3.9454 8.18578 5.82693 9.44014L7.31768 10.434C8.21393 11.0315 8.66205 11.3302 9.16668 11.3302C9.67131 11.3302 10.1194 11.0315 11.0157 10.434Z"
                              stroke="#374151"
                              stroke-width="1.6"
                              stroke-linecap="round"
                            />
                          </svg>

                          <span className="px-2 font-medium text-sm sm:text-base text-gray-700 transition-all duration-500 ">
                            {data.Subject}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-5 items-center justify-between py-2 md:py-4">
                      <div className="flex items-center gap-4">
                        <button
                          onClick={() => handlePhoneCall(data.phone)}
                          className="py-2 px-4 sm:py-3 sm:px-5 rounded-full bg-[#ffb923] text-white font-semibold text-sm sm:text-base shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-100 hover:bg-[#ffc64a]"
                        >
                          {t("Phone")}
                        </button>
                        <button
                          onClick={() => handleSendEmail(data.email)}
                          className="py-2 px-4 sm:py-3 sm:px-5 rounded-full bg-gray-50 text-[#ffb923] font-semibold text-sm sm:text-base shadow-sm shadow-transparent transition-all duration-500 hover:bg-gray-100"
                        >
                          {t("Email")}
                        </button>
                      </div>
                      {/* <div class="flex flex-col md:flex-row items-center gap-4 md:gap-6">
                <p class="flex items-center gap-2 font-medium text-sm sm:text-base text-gray-400">
                    Skills
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.78135 5.55191C9.4453 3.5173 9.77728 2.5 10.3928 2.5C11.0083 2.5 11.3403 3.5173 12.0043 5.55191L12.2949 6.44244C12.4784 7.00479 12.5701 7.28596 12.7928 7.44706C13.0155 7.60816 13.3125 7.60816 13.9063 7.60816H14.8683C17.0355 7.60816 18.119 7.60816 18.3081 8.19335C18.4972 8.77854 17.6169 9.40763 15.8563 10.6658L15.0921 11.2118C14.6069 11.5586 14.3643 11.732 14.278 11.9937C14.1918 12.2554 14.2841 12.5382 14.4687 13.1038L14.7569 13.9872C15.4209 16.0218 15.7529 17.0391 15.2549 17.3993C14.7569 17.7595 13.8878 17.1308 12.1496 15.8733L11.3887 15.323C10.9083 14.9754 10.6681 14.8016 10.3928 14.8016C10.1175 14.8016 9.87731 14.9754 9.39687 15.323L8.63605 15.8733C6.89779 17.1308 6.02866 17.7595 5.5307 17.3993C5.03273 17.0391 5.36471 16.0218 6.02866 13.9872L6.31927 13.0966C6.50278 12.5343 6.59454 12.2531 6.50948 11.9924C6.42441 11.7318 6.18419 11.558 5.70375 11.2104L4.94293 10.6601C3.20467 9.40261 2.33555 8.77389 2.52575 8.19102C2.71596 7.60816 3.79026 7.60816 5.93886 7.60816H6.87929C7.47315 7.60816 7.77008 7.60816 7.99277 7.44706C8.21547 7.28596 8.30723 7.00479 8.49074 6.44244L8.78135 5.55191Z" stroke="#9CA3AF" stroke-width="1.6"/>
                    </svg>
                </p>
                <span class="text-sm sm:text-base">{data.skills}</span>
            </div> */}
                    </div>

                    <div className=" mt-[50px]  rounded-lg  bg-white">
                      <div className="w-full my-auto py-6 flex flex-col justify-center gap-2">
                        <div className="w-full flex lg:flex-row  flex-col  xs:flex-col gap-2 justify-center">
                          <div className="w-[90%]">
                            <dl className="text-gray-900 ">
                              <div className="flex flex-col pb-3">
                                <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                  {t("experience")}
                                </dt>
                                <dd className="text-md font-[500] main-freeLancer-container-text">
                                  {data.numberOf_years_teaching} {t("years")}
                                </dd>
                              </div>
                              <div className="flex flex-col py-3">
                                <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                  {" "}
                                  {t("price")}{" "}
                                </dt>
                                <dd className="text-md font-[500] main-freeLancer-container-text">
                                  {data.price}
                                </dd>
                              </div>
                              <div className="flex flex-col py-3">
                                <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                  {t("study_mode")}
                                </dt>
                                <dd className="text-md font-[500] main-freeLancer-container-text">
                                  {data ? `${data.study_mode} ` : null}
                                </dd>
                              </div>
                            </dl>
                          </div>
                          <div className="w-full">
                            <dl className="text-gray-900 ">
                              <div className="flex flex-col pb-3">
                                <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                  {" "}
                                  {t("Certifications")}{" "}
                                </dt>
                                <dd className="text-md font-[500] main-freeLancer-container-text">
                                  {data.Certifications === "Bachelor's Degree"
                                    ? t("bachelors")
                                    : data.Certifications === "master's degree"
                                    ? t("master")
                                    : t("Doctorate")}
                                </dd>
                              </div>

                              <div className="flex flex-col py-3">
                                <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                  {t("duration")}
                                </dt>
                                <dd className="text-lg font-[500] main-freeLancer-container-text">
                                  {data.duration}
                                </dd>
                              </div>

                              <div className="flex flex-col py-3">
                                <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                  {t("grade_level")}
                                </dt>
                                <dd className="text-lg font-[500] main-freeLancer-container-text">
                                  {data.grade_level === "primary school"
                                    ? t("Primary_School")
                                    : data.grade_level === "middle school"
                                    ? t("Middle_School")
                                    : t("High_School")}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col mt-[-10px] mb-[30px]">
                        <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                          {t("Description")}
                        </dt>
                        <dd className="text-md font-[500] main-freeLancer-container-text">
                          {data ? `${data.description}  ` : null}
                        </dd>
                      </div>
                      <div className=" max-w-full">
                        <Box className="mb-[-20px] ">
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                              {t("Reviews")}
                            </dt>
                          </Box>
                          {review_data.map((review, index) => (
                            <div key={index} className="my-[10px]">
                              <h1 className="font-[600] text-gray-700 mb-[5px]  break-words">
                                {review.user_name}
                              </h1>
                              <Box
                                sx={{
                                  "& > legend": { mt: 2 },
                                }}
                              >
                                <Rating
                                  name="size-small"
                                  readOnly
                                  value={Number(review.rating)}
                                  size="small"
                                />
                              </Box>
                              <p className="text-[14px] break-words">
                                {" "}
                                {review.comment}{" "}
                              </p>
                              {/* bo awaya ka axer dana hr e nabe */}
                              {index !== review_data.length - 1 && (
                                <hr className="min-w-full mt-[10px]" />
                              )}
                            </div>
                          ))}
                          {review_data
                            ? review_data.length === 0 && (
                                <div className="search-message my-[10px] text-[14p items-center h-[100%] main-freeLancer-container-text">
                                  {t("not_haveData")}
                                </div>
                              )
                            : setLoading(true)}
                        </Box>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="max-w-full">
                  <HomeFooter />
                </div>
              </div>
            </div>
          ) : (
            <Pricing />
          )
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default Lecturers_Profile;
