import React from "react";
import { Box, Button } from "@mui/material";
import "../../assets/style/home.headers.css";
import FindInPageIcon from "@mui/icons-material/YoutubeSearchedFor";

import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Headers = () => {
    const { t, i18n } = useTranslation();

  return (
    //     <div
    //       className="headers text-4xl   flex  leading-tight md:text-7xl font-[600] tracking-tight text-gray-900"
    //       style={{ fontFamily: "Poppins, sans-serif" }}
    //     >
    //       <div className="cont  flex items-center">
    //         <div className=" main-text    ml-[50px] container  text-[70px]  ">
    //           <h1
    //             style={{ fontFamily: " Roboto, sans-serif" }}
    //             className=" main-text   text-[70px] "
    //           >
    // Find Opportunities          </h1>
    //           <div className=" ">
    //             <h1 className="main-text ">
    //               {" "}
    //            Craft Your   <span className=" text-[#ffb923]"> CV</span>{" "}
    //             </h1>
    //           </div>
    //           <div className="header-button-container mt-[50px]  ">
    //             <Button
    //               className="button-header  rounded text-white"
    //               style={{ fontFamily: "Poppins, sans-serif",color: "white" }}
    //             >
    //               Get started
    //             </Button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   <div className="pt-[50px]">

    //       <section className="w-full flex items-center max-h-[100vh]   min-h-[100vh] bg-gray-50 dark:bg-gray-900">
    //       <div className=" mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
    //         <div className="text-center space-y-5">
    //           <h2 className="text-[35px] font-semibold text-4xl header-subMain-text text-[#ffb923] pb-[20px] tracking-wide ">
    //        Your Pathway to Professional Success
    //           </h2>
    //           <div className="inline-flex items-end justify-center w-full text-center mx-auto">
    //             <img
    //               src="https://cdn.devdojo.com/tails/avatars/024.jpg"
    //               className="absolute transform translate-x-24 ml-6 rounded-full w-12 h-12 md:w-16 md:h-16 border-4 border-white"
    //               alt="Avatar 1"
    //             />
    //             <img
    //               src="https://cdn.devdojo.com/tails/avatars/012.jpg"
    //               className="absolute transform -translate-x-24 -ml-6 rounded-full w-12 h-12 md:w-16 md:h-16 border-4 border-white"
    //               alt="Avatar 2"
    //             />
    //             <img
    //               src="https://cdn.devdojo.com/tails/avatars/029.jpg"
    //               className="absolute transform -translate-x-16 rounded-full w-16 h-16 md:w-20 md:h-20 border-4 border-white"
    //               alt="Avatar 3"
    //             />
    //             <img
    //               src="https://cdn.devdojo.com/tails/avatars/005.jpg"
    //               className="absolute transform translate-x-16 rounded-full w-16 h-16 md:w-20 md:h-20 border-4 border-white"
    //               alt="Avatar 4"
    //             />
    //             <img
    //               src="https://cdn.devdojo.com/tails/avatars/032.jpg"
    //               className="rounded-full w-20 h-20 md:w-24 md:h-24 border-4 border-white relative"
    //               alt="Avatar 5"
    //             />
    //           </div>
    //           <p

    //           className="mt-1 text-4xl header-main-text font-extrabold text-gray-900 dark:text-gray-100  sm:tracking-tight lg:text-7xl xs:text-[1px]">
    // Your Next Career Move            <span className="px-2 py-1 relative inline-block">
    //               <svg
    //                 className="stroke-current bottom-0 absolute text-[#ffc546] -translate-x-2"
    //                 viewBox="0 0 410 18"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   d="M6 6.4c16.8 16.8 380.8-11.2 397.6 5.602"
    //                   strokeWidth="12"
    //                   fill="none"
    //                   fillRule="evenodd"
    //                   strokeLinecap="round"
    //                 />
    //               </svg>
    //               <span className="relative header-main-text"
    //               style={{ lineHeight:"10px" }}
    //               >Starts Here</span>
    //             </span>
    //           </p>
    //           <p className="header-subMain-text  max-w-3xl mt-5 mx-auto text-2xl text-gray-500 dark:text-gray-300">
    // Streamline your job search and create standout CVs with our expert tools          </p>

    //             <Button
    //                 className="header-button  inline-block px-6 w-[200px] py-3 mt-8  text-white font-semibold rounded  "
    //                 style={{ color: "#ffff" }}
    //               >
    //                 Get started
    //               </Button>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    <div
          style={{
          direction:
            i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
             fontFamily: "Poppins, sans-serif"
        }}
        className="mx-auto pt-[80px]  min-h-[100vh] max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 text-center  flex items-center justify-center"
    >
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-black/15 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width="200"
            height="200"
            x="100%"
            y="-1"
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none"></path>
          </pattern>
        </defs>
        {/* <svg x="50%" y="-1" className="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0"></path>
          </svg> */}
        <rect
          width="100%"
          height="100%"
          strokeWidth="0"
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
        ></rect>
      </svg>
      <div className="text-center">
        <p className="mx-auto -mt-4 max-w-2xl sm:text-lg text-sm tracking-tight text-slate-700 sm:mt-6">
{t("welcome_to")}          <span className="border-b border-dotted border-yellow-300">
            {" "}
         <span className="uppercase">job version</span>       </span>
        </p>

        <h1 className="mx-auto max-w-4xl font-display text-[30px] font-medium tracking-tight text-slate-900    lg:text-7xl md:text-5xl">
          <span className="inline-block"
          
          
    >
<span       style={{

display: (i18n.language === "ku" || i18n.language === "ar") ? "none" : "inline"
          }}>
             {t("header_main_text")} 
</span>            <span
          className={i18n.language === "ar"||"ku"?  "relative  text-[#ffb923] mr-3":  "inline-block ml-3"}



>
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute top-2/3 left-0 h-[0.58em] w-full fill-[#ffe1a2]"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
              </svg>
              <span className="relative" >           {t("header_main_text1")} 
 </span>
            </span>
          </span>
          <span className=
          {i18n.language === "ar"||"ku"?  "inline-block  mr-3":  "inline-block ml-3"}
          
          
        >   {t("header_main_text2")} </span>
        </h1>

        <p className="mx-auto mt-9 max-w-2xl text-sm tracking-tight sm:text-lg text-slate-700 sm:mt-6 lg:text-lg">
         {t("header_sub_main_text")} 
        </p>

        <div className="mt-12 flex flex-col justify-center gap-y-5 sm:mt-10 sm:flex-row sm:gap-y-0 sm:gap-x-6">
          <Link
            to={"resumes=1"}
            className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900 animate-fade-in-left"
            href="#"
          >
            <AddIcon />

            <span className={i18n.language === "en"?"ml-1.5 font-semibold text-[14px] ":"mr-2   text-[14px] font-semibold "}>  {t("Create_resume")}  </span>
          </Link>
          <div
            className="relative flex flex-1 flex-col items-stretch sm:flex-none"
            data-headlessui-state=""
          >
            
          <Link to={'/jobs'}>
            <button
              className="group  inline-flex w-full ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300 animate-fade-in-right"
              id="headlessui-menu-button-:r4:"
              aria-haspopup="true"
              aria-expanded="false"
              data-headlessui-state=""
              type="button"
            >
              <FindInPageIcon className="text-[#ffb923]" />
              <span className={i18n.language === "en"?"ml-3 font-semibold  text-[14px] ":"mr-3 font-semibold  text-[14px] "}>{t("Find_Job")} </span>
            </button>
          </Link>
          </div>
        </div>
      </div>
      <svg
    className="absolute inset-x-0 bottom-0 h-[100px] w-full -z-10 text-white"
    viewBox="0 0 1440 320"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    fill="#ffffff"
  >
    <path
      fillOpacity="0.9"
      d="M0,128L30,122.7C60,117,120,107,180,122.7C240,139,300,181,360,186.7C420,192,480,160,540,160C600,160,660,192,720,186.7C780,181,840,139,900,128C960,117,1020,139,1080,144C1140,149,1200,139,1260,144C1320,149,1380,181,1410,192L1440,203L1440,320L0,320Z"
    ></path>
  </svg>
    </div>
  );
};
export default Headers;
