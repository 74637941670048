import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useTranslation } from "react-i18next";
import first_lecturer from "../../../assets/image/first-lecturer.jpg";
import second_lecturer from "../../../assets/image/second-lecturer.jpg";
import third_lecturer from "../../../assets/image/third-lecturer.jpg";
import HomeFooter from "../../../sub component/home/footer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { user_action } from "../../../redux/action/cv-action";
import Loading from "../../../sub component/show design data/loading";
import { Helmet } from "react-helmet";

const Main_Lecturers = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  return (
    <div className="h-full w-full">

<Helmet>
  {/* Basic Meta Tags */}
  <title>Teachers - Job Version</title>
  <meta
    name="description"
    content="Explore a diverse range of teachers on Job Version. Find qualified educators across various subjects to meet your teaching needs."
  />
  <meta
    name="keywords"
    content="Teachers, Educators, Job Version, Education, Qualified Teachers, Subject Experts"
  />
  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  {/* Open Graph Tags */}
  <meta property="og:title" content="Teachers - Job Version" />
  <meta
    property="og:description"
    content="Discover a wide selection of teachers on Job Version. Connect with skilled educators across different subjects to enhance learning opportunities."
  />
  <meta
    property="og:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta
    property="og:url"
    content="https://jobversion.com/teachers"
  />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Job Version" />

  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Teachers - Job Version"
  />
  <meta
    name="twitter:description"
    content="Explore a diverse range of teachers on Job Version. Find qualified educators across various subjects to meet your teaching needs."
  />
  <meta
    name="twitter:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta
    name="twitter:url"
    content="https://jobversion.com/teachers"
  />

  {/* Canonical Tag */}
  <link
    rel="canonical"
    href="https://jobversion.com/teachers"
  />

  {/* Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "Teachers",
        "description": "Explore a diverse range of teachers on Job Version. Find qualified educators across various subjects to meet your teaching needs.",
        "image": "https://jobversion.com/images/Brand-photos.png",
        "url": "https://jobversion.com/teachers",
        "publisher": {
          "@type": "Organization",
          "name": "Job Version",
          "logo": {
            "@type": "ImageObject",
            "url": "https://jobversion.com/images/Brand-photos.png"
          }
        }
      }
    `}
  </script>
</Helmet>

      {loading ? (
        <Loading />
      ) : (
<div className="flex justify-between flex-col min-h-screen">
          <div
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
            className="  mt-[90px]  max-w-[1565px] freelance-div justify-center  mx-auto "
          >
            <div className="flex justify-center">
              <ul className="grid grid-cols-1 xl:grid-cols-3 gap-y-10 gap-x-6 items-start p-4   ">
                <li className="relative flex gap-3 flex-col sm:flex-row xl:flex-col items-center">
                  <div className="order-1 ">
                    <h3 className="mb-1 text-slate-900 font-semibold">
                      <span className="mb-1 block text-sm leading-6 text-indigo-500">
                        {t("Primary_School")}
                      </span>
                      {t("primary_header")}
                    </h3>
                    <div className="prose prose-slate prose-sm text-slate-600">
                      <p>{t("primary_content")}</p>
                    </div>

                    {/* <Link
                className="group gap-2   inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-slate-100 text-slate-700 hover:bg-slate-200 hover:text-slate-900 focus:ring-slate-500 mt-6"
to={'lecturers/Primary-School-Lecturers'}              >
                {t("Show_all")}
            
<p className="text-slate-500">            {">"}
</p>              </Link>  */}
                  </div>
                  <Link
                    to={"/teachers/Primary-School-Teachers"}
                    className="relative text-center frfrr third_lecturer flex justify-center items-center text-whitem shadow-md  h-[195px] rounded-lg bg-slate-50 w-full sm:mb-0 xl:mb-6"
                  >
                    <img
                      src={first_lecturer}
                      alt=" lecturers - image"
                      title=" lecturers - karbian"
                      className="third_lecturer flex justify-center items-center text-white text-center h-[195px]   shadow-md rounded-lg bg-slate-50 w-full sm:mb-0 "
                    />
                    <div className="absolute inset-0 flex items-center justify-center text-white font-[500] text-3xl">
                      {t("Primary_School")}
                    </div>
                  </Link>
                </li>
                <li className="relative flex gap-3 flex-col sm:flex-row xl:flex-col items-center">
                  <div className="order-1 ">
                    <h3 className="mb-1 text-slate-900 font-semibold">
                      <span className="mb-1 block text-sm leading-6 text-purple-500">
                        {t("Middle_School")}
                      </span>
                      {t("middle_header")}
                    </h3>
                    <div className="prose prose-slate prose-sm text-slate-600">
                      <p>{t("middle_content")}</p>
                    </div>
                  </div>
                  <Link
                    to={"/teachers/Middle-School-Teachers"}
                    className="relative frfrr third_lecturer flex justify-center items-center text-whitem shadow-md  h-[195px] rounded-lg bg-slate-50 w-full sm:mb-0 xl:mb-6"
                  >
                    <img
                      src={second_lecturer}
                      alt=" lecturers - image"
                      title=" lecturers - karbian"
                      className="third_lecturer lecturer-image flex justify-center items-center text-white h-[195px]  shadow-md rounded-lg bg-slate-50 w-full sm:mb-0 "
                    />
                    <div className="absolute inset-0 flex items-center justify-center text-white font-[500] text-3xl">
                      {t("Middle_School")}
                    </div>
                  </Link>
                </li>
                <li className="relative flex gap-3 flex-col sm:flex-row xl:flex-col items-center">
                  <div className="order-1">
                    <h3 className="mb-1 text-slate-900 font-semibold">
                      <span className="mb-1 block text-sm leading-6 text-cyan-500">
                        {t("High_School")}
                      </span>
                      {t("High_header")}
                    </h3>
                    <div className="prose prose-slate prose-sm text-slate-600">
                      <p>{t("High_content")}</p>
                    </div>

                    {/* <Link
                className="group gap-2  inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-slate-100 text-slate-700 hover:bg-slate-200 hover:text-slate-900 focus:ring-slate-500 mt-6"
to={'lecturers/High-School-Lecturers'}              >
                {t("Show_all")}
            
<p className="text-slate-500">            {">"}
</p>              </Link>              */}
                  </div>
                  <Link
                    to={"/teachers/High-School-teachers"}
                    className="relative frfrr first_lecturer flex justify-center items-center text-whitem shadow-md  h-[195px] rounded-lg bg-slate-50 w-full sm:mb-0 xl:mb-6"
                  >
                    <img
                      src={third_lecturer}
                      alt=" lecturers - image"
                      title=" lecturers - karbian"
                      className="third_lecturer  flex justify-center items-center text-white h-[195px]  shadow-md rounded-lg bg-slate-50 w-full sm:mb-0 "
                    />
                    <div className="absolute inset-0 flex items-center justify-center text-white font-[500] text-3xl">
                      {t("High_School")}
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <HomeFooter />
        </div>
      )}
    </div>
  );
};

export default Main_Lecturers;
