import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HomeFooter from "../../sub component/home/footer";
import { Helmet } from "react-helmet";
import { user_action } from "../../redux/action/cv-action";
import { useDispatch } from "react-redux";
import Loading from "../../sub component/show design data/loading";

const Cv_type = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(user_action());
    

        setLoading(false);
      } catch {}
    };
    fetchData();
  }, [dispatch]);
  return (
    <>
  <Helmet>
  {/* Basic Meta Tags */}
  <title>Types of CVs - Job Version</title>
  <meta
    name="description"
    content="Explore the different types of CVs and learn which format suits your career goals best. Job Version provides detailed insights into various CV formats to help you create an effective resume."
  />
  <meta
    name="keywords"
    content="types of CVs, CV formats, resume formats, CV types, career tips, CV guide"
  />
  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  
  {/* Open Graph Tags */}
  <meta property="og:title" content="Types of CVs - Job Version" />
  <meta
    property="og:description"
    content="Discover the different types of CVs and find out which format is ideal for your career objectives. Our comprehensive guide helps you choose the right CV format to make a strong impression."
  />
  <meta
    property="og:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta property="og:url" content="https://jobversion.com/cv/type" />
  <meta property="og:type" content="article" />
  <meta property="og:site_name" content="Job Version" />
  
  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Types of CVs - Job Version" />
  <meta
    name="twitter:description"
    content="Understand the different CV formats and choose the best one for your career goals. Job Version offers in-depth guidance on various CV types to help you create an impactful resume."
  />
  <meta
    name="twitter:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta name="twitter:url" content="https://jobversion.com/cv/type" />
  
  {/* Canonical Tag */}
  <link rel="canonical" href="https://jobversion.com/cv/type" />
  
  {/* Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": "Types of CVs - Job Version",
        "description": "Understand the different CV formats and choose the best one for your career goals. Job Version offers in-depth guidance on various CV types to help you create an impactful resume.",
        "image": "https://jobversion.com/images/Brand-photos.png",
        "url": "https://jobversion.com/cv/type",
        "publisher": {
          "@type": "Organization",
          "name": "Job Version",
          "logo": {
            "@type": "ImageObject",
            "url": "https://jobversion.com/images/Brand-photos.png"
          }
        }
      }
    `}
  </script>
</Helmet>

{

  loading?
  <Loading/>
  :
<div className="flex justify-between flex-col min-h-screen">
<div
        style={{
          direction:
            i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
        }}
        className="quations container  md:pt-[140px] pt-[110px]"
      >
        {" "}
        <div className="mx-[-10px] ">
          <h1 className="quation-title font-bold text-[50px] mx-[100px]">
            {" "}
            {t("types_there")}
          </h1>
          <br />
          <br />
          <div className="articles-text text-gray-700 mx-[100px]">
            {t("type_main")}
            <br />
            <br />
            <p className="font-[600] text-gray-900  text-[25px]">
              {" "}
              {t("skill_based_header")} :
            </p>
            {t("skill_based")}
            <br />
            <br />
            <p className="font-[600] text-[25px] text-gray-900 ">
              {" "}
              {t("other_header")} :
            </p>
            {t("other_types")}
            <br />
            <br />
            <p className="font-[600] text-gray-900  text-[25px]">
              {" "}
              {t("creative_header")} :
            </p>
            {t("creative_cv")} <br />
            <br />
            <br />
            <p className="font-[600] text-[25px]"> {t("europas_header")} : </p>
            {t("Europass_cv")} <br />
            <br />
            <p className="font-[600] text-[25px]">{t("Academic_header")} : </p>
            {t("academic_cv")}
          </div>
          <br />
          <br />
        </div>
      </div>
  
      <HomeFooter />
</div>

}
   
    </>
  );
};

export default Cv_type;
