import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { freelancer_get } from "../../redux/action/freelancer-action";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import brand_image from "../../assets/image/Brand-photos.png";

import "swiper/css/autoplay"; // Import the autoplay styles
import { Navigation, Pagination, Autoplay } from "swiper/modules"; // Import the Autoplay module
import "../../assets/style/freelance.css";
import LocationOnIcon from "@mui/icons-material/PlaceOutlined";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import Cookies from "js-cookie";

const FreeLancer = () => {
  const dispatch = useDispatch();
  const [load, setload] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setload(false)
      await dispatch(freelancer_get());
      setload(true)
    };
    fetchData();
  }, [dispatch]);
  
  const freeLanceData = useSelector((state) => state.freelancer.freelancer_get);
  useEffect(()=>{
    console.clear();
    },[load])
  const [displayData, setDisplayData] = useState([]);
  const token = useSelector( 
    (state) => state.cv.isAuthuntucated
  );
  // useEffect(() => {
  //   const updateDisplayData = () => {
  //     const width = window.innerWidth;
  //     if (width <= 1500) {
  //       setDisplayData(freeLanceData.slice(0, 6));
  //     } else {
  //       setDisplayData(freeLanceData.slice(0, 12));
  //     }
  //   };

  //   updateDisplayData();
  //   window.addEventListener("resize", updateDisplayData);

  //   return () => window.removeEventListener("resize", updateDisplayData);
  // }, [freeLanceData]);
  const { t, i18n } = useTranslation();

  return (
    <div className="">
      <div className="my-[170px] max-w-[1570px] freelance-div  justify-center mx-auto">
        <h1 className="text-center works_title  leading-tight mb-[30px] font-[500] tracking-tight text-gray-900">
          {t("Freelancers")}
        </h1>
        <section className=" ">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="mb-14 flex flex-col justify-center items-center sm:flex-row sm:items-center sm:justify-between max-sm:gap-8">
              <h2 className="text-4xl text-center font-bold text-gray-900 lg:text-left"></h2>

              <div className="flex items-center mb-[-30px] mr-2 gap-2.5">
                <button className="jobs-button-prev group flex justify-center items-center border border-solid min-w-12 h-12 transition-all duration-500 rounded-full hover:bg-[#ffb923]">
                  <svg
                    className="h-6 w-6 text-[#ffb923] group-hover:text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.9999 12L4.99992 12M9.99992 6L4.70703 11.2929C4.3737 11.6262 4.20703 11.7929 4.20703 12C4.20703 12.2071 4.3737 12.3738 4.70703 12.7071L9.99992 18"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button className="jobs-button-next group flex justify-center items-center border border-solid min-w-12 min-h-12 transition-all duration-500 rounded-full hover:bg-[#ffb923]">
                  <svg
                    className="h-6 w-6 text-[#ffb923] group-hover:text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 12L19 12M14 18L19.2929 12.7071C19.6262 12.3738 19.7929 12.2071 19.7929 12C19.7929 11.7929 19.6262 11.6262 19.2929 11.2929L14 6"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="lg:flex grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-8 swiper mySwiper">
              <Swiper
                slidesPerView={4}
                spaceBetween={28}
                loop={true}
                autoplay={{ delay: 2000, disableOnInteraction: false }} // Autoplay settings
                navigation={{
                  nextEl: ".jobs-button-next",
                  prevEl: ".jobs-button-prev",
                }}
                modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  468: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  668: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },

                  968: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1100: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                  1500: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                  },
                }}
              >
               {
                 freeLanceData.map((data)=>(

                  <SwiperSlide >
                  <Link
                        to={token ?`/freelance/freelance-detail/${data.id}`:'/login'}
                        
                        className="hover:text-gray-800"

                
                  >
                    <div className="flex justify-center ">
                      <div className=" overflow-hidden slider-container rounded-lg border w-[300px] ">
                        <div className="relative  ">
                          <img
                              src={data.image || brand_image}
                              className="w-full slider-image min-h-[190px] max-h-[190px] "
                            alt="job version - freelance"
                            title="job version - freelance"

                          />
                          <div className="hover:bg-transparent rounded transition duration-300 absolute bottom-0 top-0 right-0 left-0 opacity-25"></div>
                          <div>
                            {/* <div class="text-xs absolute truncate h-[30px] max-w-[200px]  min-w-[150px]   top-0 right-0 bg-[#ffb923]/50 px-4 py-2 text-white mt-3 mr-3  transition duration-500 ease-in-out">
                        {item.functional_area}
                      </div> */}
                          </div>
                        </div>
                        <div className="px-2 mt-2 mb-auto">
                          <h1 className="slider-text text-[15px] truncate font-[500]">
                          {data.name}                          </h1>
                          <div className="flex gap-1 mt-[7px]">
                            <LocationOnIcon
                              className="slider-text mt-[1px] "
                              style={{
                                fontSize: "15px",
                                marginBottom: "3px",
                                color: "gray",
                              }}
                            />

                            <p className="text-gray-500 slider-text max-h-[36px]  text-[12px] over  break-words truncate">
                            {data.country} ,    {data.state}  ,    {data.city}    
                            </p>
                          </div>
                          <div className="flex gap-1 mb-[5px]">
                            <BusinessCenterOutlinedIcon
                              className="slider-text mt-[1px] "
                              style={{
                                fontSize: "15px",
                                marginBottom: "3px",
                                color: "gray",
                              }}
                            />

                            <p className="text-gray-500 slider-text  text-[12px] over  break-words truncate">
                            {data.experience}   {t("Years")}   Experience
                            </p>
                          </div>
                        </div>
                        <div className="px-3 py-1 mb-[5px] flex flex-row items-center max-h-[30px] truncate text-gray-700  text-[13px] justify-center bg-gray-100 rounded text-center   mx-[5px]  ">
                          <div className="truncate  slider-text ">
                            <div className="flex gap-1 ">
                              <p className="text-gray-500 slider-text  text-[12px] over  break-words truncate">
                              {data.per_hour_price} {data.currency}  / Hour
                              </p>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                  
                 ))
               }
               
              </Swiper>
            </div>
          </div>
        </section>
        <div className="flex mt-[-65px] z-10 w-full justify-end ">
          <Link
                to={"/freelance"}

            className="relative w-[140px] z-10 h-[56px] overflow-hidden border-none hover:text-gray-500 text-black bg-none pb-8 cursor-pointer group"
          >
            <div className="absolute inset-0 flex justify-center items-center clone">
              <span className="ml-1 underline">{t("Show_all")}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FreeLancer;
