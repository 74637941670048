import React, { useEffect, useState } from "react";
import HomeFooter from "../sub component/home/footer";
import cv1 from "../assets/image/CV template/cv1.jpeg";
import cv2 from "../assets/image/CV template/cv2.jpeg";
import cv3 from "../assets/image/CV template/cv3.jpeg";
import cv4 from "../assets/image/CV template/cv4.jpeg";
import cv5 from "../assets/image/CV template/cv5.jpeg";
import cv6 from "../assets/image/CV template/cv6.jpeg";
import cv7 from "../assets/image/CV template/cv7.jpeg";
import cv8 from "../assets/image/CV template/cv8.jpeg";
import cv9 from "../assets/image/CV template/cv9.jpeg";
import cv10 from "../assets/image/CV template/cv10.jpeg";
import cv11 from "../assets/image/CV template/cv11.jpeg";
import cv12 from "../assets/image/CV template/cv12.jpeg";
import cv13 from "../assets/image/CV template/cv13.jpeg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { user_action } from "../redux/action/cv-action";
import { useDispatch } from "react-redux";
import Loading from "../sub component/show design data/loading";
import { Helmet } from "react-helmet";

const Cv_templates = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());

      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  return (
    <>
    <>
  <Helmet>
    <title>
      Create CV - Job Version
    </title>
    <meta
      name="description"
      content="Explore our wide range of free, customizable CV templates designed for all industries. Create your professional CV effortlessly with Job Version's user-friendly templates, available in multiple designs and languages."
    />
    <meta
      name="keywords"
      content="CV templates, job version  professional CV, free CV templates, customizable CV, resume builder, CV designs, industry-specific CV"
    />
    <meta name="robots" content="index, follow" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    {/* Open Graph Tags */}
    <meta
      property="og:title"
      content="CV Templates - Free Customizable Templates for All Industries - Job Version"
    />
    <meta
      property="og:description"
      content="Explore our wide range of free, customizable CV templates designed for all industries. Create your professional CV effortlessly with Job Version's user-friendly templates, available in multiple designs and languages."
    />
    <meta
      property="og:image"
      content="https://jobversion.com/images/Brand-photos.png"
    />
    <meta property="og:url" content="https://jobversion.com/CV-templates" />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="Job Version" />

    {/* Twitter Card Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:title"
      content="CV Templates - Free Customizable Templates for All Industries - Job Version"
    />
    <meta
      name="twitter:description"
      content="Explore our wide range of free, customizable CV templates designed for all industries. Create your professional CV effortlessly with Job Version's user-friendly templates, available in multiple designs and languages."
    />
    <meta
      name="twitter:image"
      content="https://jobversion.com/images/Brand-photos.png"
    />
    <meta name="twitter:url" content="https://jobversion.com/CV-templates" />

    {/* Canonical Tag */}
    <link rel="canonical" href="https://jobversion.com/CV-templates" />

    {/* Structured Data */}
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "headline": "Create CV",
          "description": "Explore our wide range of free, customizable CV templates designed for all industries. Create your professional CV effortlessly with Job Version's user-friendly templates, available in multiple designs and languages.",
          "image": https://jobversion.com/images/Brand-photos.png",
          "url": "https://jobversion.com/CV-templates",
          "publisher": {
            "@type": "Organization",
            "name": "Job Version",
            "logo": {
              "@type": "ImageObject",
              "url": https://jobversion.com/images/Brand-photos.png"
            }
          }
        }
      `}
    </script>
  </Helmet>

</>

      {loading ? (
        <Loading />
      ) : (
        <div className="my-[80px]  xl:max-w-[1570px] max-w-[1200px]  justify-center mx-auto">
          <div className="py-[50px]">
            <h1 className="text-center  lg:text-[50px] md:text-[40px] text-[30px] leading-tight mb-[20px]  font-[600] tracking-tight text-gray-900">
              {t("CV_templates")}{" "}
            </h1>
            <div className=" min-w-[100%] xl:px-[70px] md:px-7 sm:px-3 px-4">
              <p className=" text-gray-700 text-center   md:text-lg text-sm  leading-tight   font-[400] tracking-tight ">
                {t("sub_title")}
              </p>
            </div>
          </div>

          <div className="min-w-[100%]">
            <div className="flex justify-center  min-w-[100%] ">
              <div className="main-freeLancer-container  flex-col max-w-[900px] main-freeLancer-details-container min-w-[100%] flex justify-center gap-[40px]">
                <div className="flex flex-col min-w-[100%]">
                  <div className="grid grid-cols-1  lg:grid-cols-3 sm:grid-cols-2    gap-4 min-w-[100%] xl:p-[70px] md:px-7 sm:p-2">
                    <Link
                      to={"/resumes=1"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv1}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>
                    <Link
                      to={"/resumes=2"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv2}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>
                    <Link
                      to={"/resumes=3"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv3}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=4"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv4}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=5"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv5}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=6"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv6}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=13"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv13}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=7"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv7}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=8"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv8}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=9"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv9}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=10"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv10}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=11"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv11}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>{" "}
                    <Link
                      to={"/resumes=12"}
                      className="pdf-container rounded-[10px] max-w-full h-full border"
                    >
                      <img
                        src={cv12}
                        alt="cv"
                        title="karbein cv"
                        className="rounded-[10px] pdf-page h-full object-cover"
                        style={{ objectFit: "cover" }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-full">
              <HomeFooter />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cv_templates;
