import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HomeFooter from "../../sub component/home/footer";
import { Helmet } from "react-helmet";
import Loading from "../../sub component/show design data/loading";
import { useDispatch } from "react-redux";
import { user_action } from "../../redux/action/cv-action";

const Purpose = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Scroll to top when component is mounted
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // Scroll to top when component is mounted
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
 
      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  return (
    <>
 <Helmet>
  {/* Basic Meta Tags */}
  <title>What is the Purpose of a CV? - Job Version</title>
  <meta
    name="description"
    content="Learn about the purpose of a CV and why it's a critical component of your career toolkit. Job Version provides insights into how a well-crafted CV can enhance your job prospects and professional growth."
  />
  <meta
    name="keywords"
    content="CV purpose, curriculum vitae, CV importance, career development, professional growth, job application"
  />
  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  {/* Open Graph Tags */}
  <meta
    property="og:title"
    content="What is the Purpose of a CV? - Job Version"
  />
  <meta
    property="og:description"
    content="Discover the purpose of a CV and how it plays a key role in advancing your career. Job Version's guide explains the significance of a well-prepared CV in the job market."
  />
  <meta
    property="og:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta property="og:url" content="https://jobversion.com/cv/purpose" />
  <meta property="og:type" content="article" />
  <meta property="og:site_name" content="Job Version" />

  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="What is the Purpose of a CV? - Job Version"
  />
  <meta
    name="twitter:description"
    content="Explore the purpose of a CV and its importance for your career development. Job Version offers a comprehensive overview of how a CV can impact your job search and professional journey."
  />
  <meta
    name="twitter:image"
    content="https://jobversion.com/images/Brand-photos.png"
  />
  <meta name="twitter:url" content="https://jobversion.com/cv/purpose" />

  {/* Canonical Tag */}
  <link rel="canonical" href="https://jobversion.com/cv/purpose" />

  {/* Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": "What is the Purpose of a CV? - Job Version",
        "description": "Understand the purpose of a CV and its crucial role in career advancement. Job Version provides detailed insights on how a CV can enhance your job prospects and professional development.",
        "image": "https://jobversion.com/images/Brand-photos.png",
        "url": "https://jobversion.com/cv/purpose",
        "publisher": {
          "@type": "Organization",
          "name": "Job Version",
          "logo": {
            "@type": "ImageObject",
            "url": "https://jobversion.com/images/Brand-photos.png"
          }
        }
      }
    `}
  </script>
</Helmet>

{
  loading?
  <Loading />

:
<div className="flex justify-between flex-col min-h-screen">
<div
    style={{
      direction:
        i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
    }}
    className="quations container  md:pt-[140px] pt-[110px]"
    >
        <div className="mx-[-10px] ">
        <h1 className="quation-title font-bold text-[50px] mx-[100px]">
        {" "}
        {t("cv_purpose_header")}
      </h1>
      <br />
      <br />
      <p className="articles-text text-gray-700 mx-[100px]">
        {t("cv_purpose_content")}

        <br />
        <br />
      </p>
    </div>
  </div>

  <HomeFooter />
</div>
}
 

    </>
  );
};

export default Purpose;
